import React, { useCallback, useState } from 'react';
import { match } from 'ts-pattern';
import { Input } from 'oemiq-common';
import { Row, Col } from 'react-bootstrap';
import './VehicleYear.scss';

type VehicleYearStatusListProps = {
    filter;
    setFilterValue;
};

export const VehicleYearList = ({ filter, setFilterValue }: VehicleYearStatusListProps) => {
    return match(filter.operator.value)
        .with(operatorsList.eq.value, operatorsList.ne.value, () => (
            <VehicleYearNumber filter={filter} setFilterValue={setFilterValue}></VehicleYearNumber>
        ))
        .with(operatorsList.range.value, () => (
            <VehicleYearRange filter={filter} setFilterValue={setFilterValue}></VehicleYearRange>
        ))
        .otherwise(() => <></>);
};

const formatValueList = (fromValue: string, toValue: string) => {
    const valueList = [];
    if (fromValue) valueList.push(`from ${fromValue}`);
    if (toValue) valueList.push(`to ${toValue}`);
    return valueList;
};

const validateVehicleYearValue = v => {
    // 1. validate number (year equal or not equal)
    if (!isNaN(v)) return parseInt(v) > 0;
    // 2. validate object with properties: from, to
    const hasValueFromOrTo = v.from || v.to;
    const areNumbers = (v.from && !isNaN(v.from)) || (v.to && !isNaN(v.to));
    if (!hasValueFromOrTo || !areNumbers) return false;
    if (v.from && parseInt(v.from) <= 0) return false;
    if (v.to && parseInt(v.to) <= 0) return false;
    return v.from && v.to ? parseInt(v.from) <= parseInt(v.to) : true;
};

const VehicleYearRange = ({ filter, setFilterValue }: VehicleYearStatusListProps) => {
    const [rangeValue, setRangeValue] = useState<{ from: string; to: string }>({
        from: filter.value?.from || '',
        to: filter.value?.to || '',
    });

    const handleFromValueChange = useCallback(
        (fromValue: string) => {
            setRangeValue({ from: fromValue, to: rangeValue.to });
            setFilterValue({
                value: { from: fromValue, to: rangeValue.to },
                valueList: formatValueList(fromValue, rangeValue.to),
            });
        },
        [rangeValue.to, setFilterValue]
    );

    const handleToValueChange = useCallback(
        (toValue: string) => {
            setRangeValue({ from: rangeValue.from, to: toValue });
            setFilterValue({
                value: { from: rangeValue.from, to: toValue },
                valueList: formatValueList(rangeValue.from, toValue),
            });
        },
        [rangeValue.from, setFilterValue]
    );

    return (
        <Row className="range-filter-values">
            <Col xs="6">
                <Input
                    id="InputYearRangeFrom"
                    label="FROM"
                    type="number"
                    step="1"
                    disabled={!filter.id || !filter.operator}
                    value={rangeValue.from}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFromValueChange(e.target.value)}
                />
            </Col>
            <Col xs="6">
                <Input
                    id="InputYearRangeTo"
                    label="TO"
                    type="number"
                    step="1"
                    disabled={!filter.id || !filter.operator}
                    value={rangeValue.to}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToValueChange(e.target.value)}
                />
            </Col>
        </Row>
    );
};

const VehicleYearNumber = ({ filter, setFilterValue }: VehicleYearStatusListProps) => {
    return (
        <Input
            className="mb-2"
            type="number"
            step="1"
            placeholder="Filter value"
            disabled={!filter.id || !filter.operator}
            value={filter.value}
            onChange={e => setFilterValue({ value: e.target.value, valueList: null })}
        />
    );
};

const operatorsList = {
    eq: {
        value: 'eq',
        label: '==',
        buildFilter: (propertyName: string, value) => `vehicles/any(v: v/yearId eq ${value})`,
    },
    ne: {
        value: 'ne',
        label: '!=',
        buildFilter: (propertyName: string, value) => `vehicles/all(v: v/yearId ne ${value})`,
    },
    range: {
        value: 'range',
        label: 'Range',
        buildFilter: (propertyName: string, value) => {
            if (value.from && !value.to) return `vehicles/any(v: v/yearId ge ${value.from})`;
            if (!value.from && value.to) return `vehicles/any(v: v/yearId le ${value.to})`;
            return `vehicles/any(v: v/yearId ge ${value.from} and v/yearId le ${value.to})`;
        },
    },
};

export default {
    component: VehicleYearList,
    format: v => v,
    validator: validateVehicleYearValue,
    operators: [operatorsList.eq, operatorsList.ne, operatorsList.range],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};

import type { DataItem } from 'components/Shared/Table/types';
import type { ImportJob } from '../types';

export interface ImportHistoryRowDataType extends ImportJob, DataItem {
    errors: number;
    numToPublish: number;
    numOfPublished: number;
}

export enum ImportHistoryActionsTypes {
    ViewDetails = '1',
}

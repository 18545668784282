import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import useOemService from 'hooks/OemModels/useOemService';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';

const autoReloadAfterChanges = false;

const useProcedures = (filterBy, orderBy, oemid, includeDeletedProcedures) => {
    const [data, setData] = useState([]);
    const [proceduresStaleData, setProceduresStaleData] = useState({});
    const [hasMoreData, setHasMoreData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState({ error: false, value: 0, loadingCount: 0, selectableCount: 0 });
    const { oemService } = useOemService(oemid);
    const page = useRef(0);
    const pageSize = 100;
    const { notifications } = useContext(NotificationsContext);

    useEffect(() => {
        setData([]);
        setProceduresStaleData({});
        setHasMoreData(true);
        page.current = 0;
    }, [filterBy, orderBy, includeDeletedProcedures]);

    const updateProceduresCount = useCallback(async () => {
        setTotalCount(c => {
            return {
                ...c,
                error: false,
                loadingCount: true,
            };
        });

        try {
            const countPromise = oemService.countProcedures(filterBy, includeDeletedProcedures);
            const selectableCountPromise = !includeDeletedProcedures
                ? countPromise
                : oemService.countProcedures(filterBy, false);

            const [count, selectableCount] = await Promise.all([countPromise, selectableCountPromise]);

            setTotalCount(c => {
                return {
                    ...c,
                    error: false,
                    value: count,
                    selectableCount,
                    loadingCount: false,
                };
            });
        } catch (e) {
            setTotalCount(c => {
                return {
                    ...c,
                    error: true,
                    value: undefined,
                    selectableCount: undefined,
                    loadingCount: false,
                };
            });
            throw e;
        }
    }, [filterBy, includeDeletedProcedures, oemService]);

    useEffect(() => {
        updateProceduresCount();
    }, [updateProceduresCount]);

    const handleReloadButton = useCallback(() => {
        page.current = 0;
        setHasMoreData(true);
        updateProceduresCount();
        setData([]);
        setProceduresStaleData({});
    }, [updateProceduresCount]);

    const updateProceduresStaleData = useCallback(
        async newProceduresIds => {
            let newStaleData = {};
            for (let i = 0; i < newProceduresIds.length; i += pageSize) {
                const resp = await oemService.getProceduresStaleData(newProceduresIds.slice(i, i + pageSize));
                for (const procedureStaleData of resp.value) {
                    newStaleData[procedureStaleData.procedureId] = procedureStaleData.updateDate;
                }
            }

            setProceduresStaleData(proceduresStaleData => ({ ...proceduresStaleData, ...newStaleData }));
        },
        [oemService]
    );

    const reloadProcedures = async () => {
        setLoading(true);
        try {
            updateProceduresCount();
            let data = [];
            for (let i = 0; i < page.current; i++) {
                const oDataResponse = await oemService.getProcedures(
                    filterBy,
                    orderBy,
                    pageSize,
                    pageSize * i,
                    includeDeletedProcedures
                );
                data = [...data, ...oDataResponse.value];
            }

            setHasMoreData(data.length === pageSize * page.current);
            setData(data);
            await updateProceduresStaleData(data.map(p => p.procedureId));
        } catch (error) {
            setData([]);
            setProceduresStaleData({});
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    };

    // needs to be updated when bulk mapper will be worked on
    const updateProcedures = procedures => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        setData(procedure => {
            const newProcedures = [...procedure];
            procedures.forEach(up => {
                const index = newProcedures.findIndex(np => np.procedureId === up.procedureId);
                newProcedures[index] = up;
            });

            return newProcedures;
        });

        return updateProceduresStaleData(procedures.map(p => p.procedureId));
    };

    const updateOemIqTypeForProcedureIds = (newOemIqType, procedureIds, statusId) => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        let mappedType = { mappingRuleId: null, mappingStatusId: statusId, typeId: newOemIqType?.value };

        setData(currProcedures => {
            return currProcedures.map(p => {
                if (procedureIds.includes(p.procedureId)) {
                    const newProcedure = { ...p };
                    newProcedure.stageArea.type = mappedType;
                    return newProcedure;
                }
                return p;
            });
        });

        return updateProceduresStaleData(procedureIds);
    };

    const setNewGroupListToProcedureByProcedureId = (newGroupList, procedureId) => {
        if (autoReloadAfterChanges) {
            return reloadProcedures();
        }

        let mappedGroups = newGroupList.map(g => {
            return {
                groupId: g.regionId,
                mappingRuleId: null,
                mappingStatusId: g.mappingWorkFlowStatus.mappingWorkFlowStatusId,
            };
        });

        setData(currProcedures => {
            return currProcedures.map(p => {
                if (p.procedureId === procedureId) {
                    const newProcedure = { ...p };
                    newProcedure.stageArea.groups = mappedGroups;
                    return newProcedure;
                }
                return p;
            });
        });

        return updateProceduresStaleData([procedureId]);
    };

    const loadMoreCallback = useCallback(async () => {
        setLoading(true);
        try {
            const oDataResponse = await oemService.getProcedures(
                filterBy,
                orderBy,
                pageSize,
                pageSize * page.current,
                includeDeletedProcedures
            );
            const data = oDataResponse.value;
            setData(d => [...d, ...data]);
            setHasMoreData(data.length === pageSize);
            await updateProceduresStaleData(data.map(p => p.procedureId));

            page.current++;
        } catch (error) {
            setData([]);
            setProceduresStaleData({});
            setHasMoreData(false);
            notifications.pushExceptionDanger(error);
        } finally {
            setLoading(false);
        }
    }, [oemService, filterBy, orderBy, includeDeletedProcedures, updateProceduresStaleData, notifications]);

    const infusedData = useMemo(
        () =>
            data.map(p => {
                const sqlUpdateDate = proceduresStaleData[p.procedureId];
                return {
                    ...p,
                    sqlUpdateDate,
                    isStale: false,
                };
            }),
        [data, proceduresStaleData]
    );

    return {
        data: infusedData,
        hasMoreData,
        loading,
        totalCount,
        loadMoreCallback,
        refreshProcedures: () => {}, // this is old code from mapper
        handleReloadButton,
        updateProcedures,
        updateOemIqTypeForProcedureIds,
        setNewGroupListToProcedureByProcedureId,
    };
};

export default useProcedures;

import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SORT_ORDER } from 'enums/SortOrderEnum';

export const OemProceduresHeaderCell = ({ element, onSorting }) => {
    const [sortable, setSortable] = useState(false);
    const sortOrderRef = useRef(0);
    const sortOrders = [
        { order: SORT_ORDER.none, icon: '' },
        { order: SORT_ORDER.asc, icon: 'sort-up' },
        { order: SORT_ORDER.desc, icon: 'sort-down' },
    ];
    useEffect(() => {
        setSortable(element.sortable);
    }, [element]);

    return (
        <th className={`text-nowrap ${element.sortable ? 'clickable' : ''}`}>
            <div
                className="d-flex align-items-center"
                onClick={() => {
                    if (sortable) {
                        sortOrderRef.current = (sortOrderRef.current + 1) % sortOrders.length;
                        onSorting(element.name, sortOrders[sortOrderRef.current].order);
                    }
                }}>
                {sortOrders[sortOrderRef.current].icon && (
                    <FontAwesomeIcon icon={sortOrders[sortOrderRef.current].icon} />
                )}
                {element.displayName}
            </div>
        </th>
    );
};

import React, { useCallback } from 'react';
import { Select } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import FilterInput from './FilterInput';

const FilterItem = ({
    id,
    columnId,
    operatorId,
    inputValue,
    options,
    action,
    handleUpdate,
    handleAddFilter,
    handleRemoveFilter,
}) => {
    const handleColumnChange = useCallback(
        value => {
            handleUpdate(id, value, operatorId, inputValue, true);
        },
        [id, operatorId, inputValue, handleUpdate]
    );

    const handleOperatorChange = useCallback(
        value => {
            handleUpdate(id, columnId, value, inputValue, false);
        },
        [id, columnId, inputValue, handleUpdate]
    );

    const handleInputChange = useCallback(
        value => {
            handleUpdate(id, columnId, operatorId, value, false);
        },
        [id, columnId, operatorId, handleUpdate]
    );

    const handleInputBlur = useCallback(
        value => {
            handleUpdate(id, columnId, operatorId, value);
        },
        [id, columnId, operatorId, handleUpdate]
    );

    return (
        <div className="d-flex mt-3">
            <button
                title="Add new filter"
                id={`oem-tagger-add-filter-button-${id}`}
                type="button"
                onClick={handleAddFilter}
                className="btn btn-primary btn-sm me-2">
                <FontAwesomeIcon icon={faPlus} />
            </button>
            <button
                title="Remove filter"
                type="button"
                id={`oem-tagger-filter-${id}`}
                onClick={() => handleRemoveFilter(id)}
                className="btn btn-danger btn-sm me-2">
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="me-2" style={{ minWidth: '180px' }}>
                <Select
                    id={`oem-tagger-filter-column-select-${id}`}
                    formGroupClassName="mb-0 align-self-center"
                    className="form-select"
                    options={options}
                    defaultOption={{ value: -1, text: 'Select Column' }}
                    value={columnId}
                    onChange={e => handleColumnChange(parseInt(e.currentTarget.value))}
                />
            </div>
            {columnId !== -1 && action && (
                <>
                    <div className="me-2" style={{ minWidth: '180px' }}>
                        <Select
                            id={`oem-tagger-filter-operators-${id}`}
                            formGroupClassName="mb-0  align-self-center"
                            className="form-select"
                            options={action.operators}
                            defaultOption={{ value: -1, text: 'Select Operator' }}
                            value={operatorId}
                            onChange={e => handleOperatorChange(parseInt(e.currentTarget.value))}
                        />
                    </div>
                    <div className="flex-grow-1">
                        <FilterInput
                            id={`oem-tagger-filter-input-component-${id}`}
                            inputTypeId={action.inputType.id}
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            {...action.inputType.props}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default FilterItem;

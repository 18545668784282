import React from 'react';
import { Input } from 'oemiq-common';
import { operatorsList } from '../operators';

export const Text = ({ filter, setFilterValue }) => {
    return (
        <Input
            className="mb-2"
            type="text"
            placeholder="Filter value"
            disabled={!filter.id || !filter.operator}
            value={filter.value}
            onChange={e => setFilterValue({ value: e.target.value, valueList: null })}
        />
    );
};

export default {
    component: Text,
    format: v => `'${v}'`,
    operators: [operatorsList.contains, operatorsList.notContains, operatorsList.eq, operatorsList.ne],
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
};

import { useState, useEffect, useContext } from 'react';
import { ReactModal, Input, PrimaryButton, Select, TextArea } from 'oemiq-common';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';

const FRANCHISE_TYPE_ID = 2;
const LOCATION_LEVEL_TYPE_ID = 2;
const DEFAULT_INCOMPATIBLE_ERROR_MESSAGE = 'Franchise type cannot be assigned to Location level';
/**
 * Validate if type and level could go together
 * @param {number} type type id
 * @param {number} level level id
 */
const areTypeAndLevelCompatible = (type, level) => !(type === FRANCHISE_TYPE_ID && level === LOCATION_LEVEL_TYPE_ID);

const AddOrEditNetworkModal = ({
    isOpen,
    onToggle,
    networkTypes,
    networkLevels,
    createNetwork,
    setNetworks,
    editNetwork,
    networkToEdit,
}) => {
    const [name, setName] = useState('');
    const [type, setNetworkType] = useState(-1);
    const [level, setNetworkLevel] = useState(-1);
    const [note, setNote] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        if (!networkToEdit) return;

        setName(networkToEdit.name);
        setNetworkType(networkToEdit.networkTypeId);
        setNetworkLevel(networkToEdit.networkLevelId);
        setNote(networkToEdit.note);
    }, [networkToEdit]);

    useEffect(() => {
        const isCompatible = areTypeAndLevelCompatible(type, level);
        setErrorMessage(isCompatible ? '' : DEFAULT_INCOMPATIBLE_ERROR_MESSAGE);
        if (networkToEdit) {
            setIsDisabled(() => {
                return (
                    (networkToEdit.name === name &&
                        networkToEdit.networkTypeId === type &&
                        networkToEdit.networkLevelId === level &&
                        networkToEdit.note === note) ||
                    // franchise cannot be assigned to location
                    !isCompatible
                );
            });
        } else {
            setIsDisabled(() => {
                return name === '' || type === -1 || level === -1 || note === '' || !isCompatible;
            });
        }
    }, [networkToEdit, name, type, level, note]);

    const closeModal = () => {
        setName('');
        setNetworkType(-1);
        setNetworkLevel(-1);
        setNote('');

        onToggle();
    };

    const onCreate = async () => {
        try {
            incrementLoading();
            let network = await createNetwork(name, type, level, note);
            setNetworks(prevState => {
                return [...prevState, network];
            });
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const onEdit = async () => {
        try {
            incrementLoading();
            const networkId = networkToEdit.networkId;
            let editedNetwork = await editNetwork(networkId, name, type, level, note);
            setNetworks(prevState => {
                let newState = [...prevState];
                let stateToEdit = newState.find(n => n.networkId === networkId);
                stateToEdit.name = editedNetwork.name;
                stateToEdit.networkTypeId = editedNetwork.networkTypeId;
                stateToEdit.networkType = editedNetwork.networkType;
                stateToEdit.networkLevelId = editedNetwork.networkLevelId;
                stateToEdit.networkLevel = editedNetwork.networkLevel;
                stateToEdit.note = editedNetwork.note;

                return newState;
            });
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const onButtonClick = async () => {
        if (networkToEdit) await onEdit();
        else await onCreate();

        closeModal();
    };

    return (
        <ReactModal
            id="create-or-edit-network"
            headerComponent={<div>{networkToEdit ? 'Edit Network' : 'Create New Network'}</div>}
            isOpen={isOpen}
            toggle={closeModal}
            bodyComponent={
                <div>
                    <Input
                        id="searchfilter-search"
                        type="text"
                        className="mb-3"
                        value={name}
                        onChange={e => setName(e.currentTarget.value)}
                        placeholder="Network Name"
                    />
                    <Select
                        id="network-types"
                        value={type}
                        className="form-select mb-3"
                        options={networkTypes.map(o => {
                            return { text: o.name, value: o.networkTypeId };
                        })}
                        defaultOption={{ value: -1, text: 'Select Type' }}
                        onChange={e => setNetworkType(parseInt(e.currentTarget.value))}
                        fullWidth={true}
                    />
                    <Select
                        id="network-levels"
                        value={level}
                        className="mb-3 form-select"
                        options={networkLevels.map(o => {
                            return { text: o.name, value: o.networkLevelId };
                        })}
                        defaultOption={{ value: -1, text: 'Select Level' }}
                        onChange={e => setNetworkLevel(parseInt(e.currentTarget.value))}
                        fullWidth={true}
                    />
                    <TextArea
                        id="form-control-input"
                        rows={3}
                        className="mb-3"
                        placeholder="Note"
                        value={note}
                        onChange={e => setNote(e.currentTarget.value)}
                    />
                </div>
            }
            footerComponent={
                <div className="d-flex align-items-center">
                    {!!errorMessage && <div className="text-danger mr-3">{errorMessage}</div>}
                    <PrimaryButton type="button" disabled={isDisabled} onClick={() => onButtonClick()}>
                        {networkToEdit ? 'Edit Network' : 'Create Network'}
                    </PrimaryButton>
                </div>
            }
        />
    );
};

export default AddOrEditNetworkModal;

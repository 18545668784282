import React, { useCallback, useContext } from 'react';
import ReactSelect from 'react-select';
import { Input } from 'oemiq-common';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModal from 'hooks/useModal';
import { match } from 'ts-pattern';
import { SingleGroupTypeModal } from 'components/locations/MappingProcess/MapperList/MapperTable/GroupTypeModal/GroupTypeModal';
import { FormatDateToGMT } from 'helpers/DateHelper';
import { Tooltip } from 'react-tooltip';

function getBadgeClassName(mappingStatusId) {
    return match(mappingStatusId)
        .with(1, () => 'text-bg-primary')
        .with(2, () => 'text-bg-success')
        .with(3, () => 'text-bg-danger')
        .otherwise(() => 'text-bg-primary');
}

export const BasicViewValueComponent = ({ value, column }) => {
    if (column.multiple) {
        return (
            value &&
            (Array.isArray(value) ? value : value.split(',')).map(column.valueProvider).map(s => (
                <span className="badge text-bg-primary me-1" key={s}>
                    {s}
                </span>
            ))
        );
    }

    return <span>{column.valueProvider(value)}</span>;
};

export const BasicEditValueComponent = ({ value, property, onChange, ...props }) => {
    const handleChange = useCallback(e => onChange(e.target.value), [onChange]);

    return <Input type={property.type.type} onChange={handleChange} value={value} {...props} />;
};

export const BoolEditValueComponent = ({ value, onChange, ...props }) => {
    return (
        <ReactSelect
            autosize={false}
            menuPortalTarget={document.body}
            styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            value={value}
            isClearable={true}
            options={[
                { value: true, label: 'true' },
                { value: false, label: 'false' },
            ]}
            onChange={onChange}
            {...props}
        />
    );
};

export const ProcedureDisplayViewValueComponent = ({ value, row, onClick }) => {
    return (
        <a onClick={() => onClick(row)} className="link-primary" style={{ cursor: 'pointer' }}>
            {value}
        </a>
    );
};

export const ProcedureIdAndHistoryComponent = ({ value, row, onHistoryClick }) => {
    return (
        <>
            <div>
                {value}{' '}
                <FontAwesomeIcon
                    className="clickable"
                    icon="history"
                    onClick={() =>
                        onHistoryClick({
                            procedureId: row.procedureId,
                            procedureTitle: row.procedureTitle,
                        })
                    }
                />
            </div>
            {row.isStale && (
                <>
                    <div className="d-flex">
                        <FontAwesomeIcon
                            icon="fa-solid fa-cloud-arrow-up"
                            size="2xl"
                            className="m-auto"
                            data-tooltip-id="procedureSyncTooltip"
                        />
                        <Tooltip id="procedureSyncTooltip" effect="solid">
                            Procedure mapping data is synchronizing
                        </Tooltip>
                    </div>
                </>
            )}
        </>
    );
};

export const GroupDisplayViewValueComponent = ({ row, clickable, setNewGroupListToProcedureByProcedureId }) => {
    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();

    const mappingDefinitions = useContext(MappingDefinitionsContext);
    const groups = row?.stageArea.groups;
    const procedureId = row.procedureId;

    if (clickable && (!mappingDefinitions?.groups?.length || !groups?.length)) {
        return (
            <button
                type="button"
                className="btn btn-outline-primary mb-1"
                style={{ fontSize: '12px', borderRadius: '24px' }}
                onClick={clickable ? () => openGroupTypeModal([procedureId]) : null}>
                Select
                {isGroupTypeModalOpen && (
                    <SingleGroupTypeModal
                        isOpen={isGroupTypeModalOpen}
                        procedure={row}
                        closeGroupTypeModal={closeGroupTypeModal}
                        setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                    />
                )}
            </button>
        );
    }

    return (
        <div>
            {groups.map(group => (
                <span
                    className={`badge ${getBadgeClassName(group.mappingStatusId)} me-1`}
                    key={group.groupId}
                    onClick={clickable ? () => openGroupTypeModal([procedureId]) : null}
                    style={clickable ? { cursor: 'pointer' } : {}}>
                    {mappingDefinitions.groups.find(g => g.value === group.groupId)?.label ?? group.groupId}
                    <span className="badge text-bg-light rounded-pill ms-1">{group.mappingRuleId ?? 'M'}</span>
                </span>
            ))}
            {isGroupTypeModalOpen && (
                <SingleGroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    procedure={row}
                    closeGroupTypeModal={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                />
            )}
        </div>
    );
};

export const GroupIdDisplayViewValueComponent = ({ row, clickable, setNewGroupListToProcedureByProcedureId }) => {
    const groups = row?.stageArea.groups;
    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();

    const procedureId = row.procedureId;

    return (
        <div>
            {groups.map(group => (
                <span
                    className={`badge ${getBadgeClassName(group.mappingStatusId)} me-1`}
                    key={group.groupId}
                    onClick={clickable ? () => openGroupTypeModal([procedureId]) : null}
                    style={clickable ? { cursor: 'pointer' } : {}}>
                    {group.groupId}
                </span>
            ))}
            {isGroupTypeModalOpen && (
                <SingleGroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    procedure={row}
                    closeGroupTypeModal={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                />
            )}
        </div>
    );
};

export const GroupEditViewValueComponent = ({ value, onChange }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        mappingDefinitions?.groups?.length && (
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                placeholder="Select Available Groups"
                isMulti={true}
                options={mappingDefinitions.groups}
                value={value}
                onChange={onChange}
            />
        )
    );
};

export const GroupIdEditViewValueComponent = ({ value, onChange }) => {
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    return (
        mappingDefinitions?.groups?.length && (
            <ReactSelect
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                placeholder="Select Available Groups"
                isMulti={true}
                options={mappingDefinitions.groups}
                value={value}
                getOptionLabel={v => v.value}
                onChange={onChange}
            />
        )
    );
};

export const TypeNameViewValueComponent = ({ value, row, clickable, setNewGroupListToProcedureByProcedureId }) => {
    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();
    const procedureId = row.procedureId;
    const { mappingRuleId, mappingStatusId } = row.stageArea.type;
    const mappingDefinitions = useContext(MappingDefinitionsContext);
    if (!mappingDefinitions.groups || !mappingDefinitions.groups.length) return;
    const oemiqType = mappingDefinitions.types.find(type => type.value === value);
    return value ? (
        <span
            className={`badge ${getBadgeClassName(mappingStatusId)} me-1`}
            key={value}
            onClick={clickable ? () => openGroupTypeModal([procedureId]) : null}
            style={clickable ? { cursor: 'pointer' } : {}}>
            {oemiqType && oemiqType.text}
            {isGroupTypeModalOpen && (
                <SingleGroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    procedure={row}
                    closeGroupTypeModal={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                />
            )}
            <span className="badge text-bg-light rounded-pill ms-1">{mappingRuleId ?? 'M'}</span>
        </span>
    ) : clickable ? (
        <button
            type="button"
            className="btn btn-outline-primary mb-1"
            style={{ fontSize: '12px', borderRadius: '24px' }}
            onClick={clickable ? () => openGroupTypeModal([procedureId]) : null}>
            Select
            {isGroupTypeModalOpen && (
                <SingleGroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    procedure={row}
                    closeGroupTypeModal={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                />
            )}
        </button>
    ) : null;
};

export const TypeIdViewValueComponent = ({ value, row, clickable, setNewGroupListToProcedureByProcedureId }) => {
    const { mappingStatusId } = row.stageArea.type;
    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();

    const procedureId = row.procedureId;

    return (
        <span
            className={`badge ${getBadgeClassName(mappingStatusId)} me-1`}
            key={value}
            onClick={clickable ? () => openGroupTypeModal([procedureId]) : null}
            style={clickable ? { cursor: 'pointer' } : {}}>
            {value}
            {isGroupTypeModalOpen && (
                <SingleGroupTypeModal
                    isOpen={isGroupTypeModalOpen}
                    procedure={row}
                    closeGroupTypeModal={closeGroupTypeModal}
                    setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                />
            )}
        </span>
    );
};

export const GmtDateDisplayViewValueComponent = ({ value }) => {
    const gmtDate = FormatDateToGMT(value);
    return <span>{gmtDate}</span>;
};

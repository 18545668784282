import React, { useEffect } from 'react';
import './../ViewProcedureModal.scss';
import { MESSAGE_EVENT_TYPE } from 'helpers/useProcedureHtmlLinkClickHandler';

const HtmlIFrame = ({ clickCallback, procedureSource, onMouseMove, onMouseClick, customStylesheet = null }) => {
    useEffect(() => {
        if (procedureSource) {
            const iframe = document.getElementById('procedure_iframe');
            const iframeDocument = iframe.contentDocument;
            iframeDocument.open();
            iframeDocument.write(procedureSource);
            iframeDocument.close();

            if (customStylesheet) {
                // add custom stylesheet to the iframe
                const head = iframeDocument.head || iframeDocument.getElementsByTagName('head')[0];
                const styleElement = iframeDocument.createElement('style');
                styleElement.type = 'text/css';
                if (styleElement.styleSheet) {
                    styleElement.styleSheet.cssText = customStylesheet;
                } else {
                    styleElement.appendChild(iframeDocument.createTextNode(customStylesheet));
                }
                head.appendChild(styleElement);
            }
        }
    }, [customStylesheet, procedureSource]);

    useEffect(() => {
        if (!clickCallback) return;

        const listener = event => {
            if (event.data?.type === MESSAGE_EVENT_TYPE && event.isTrusted && event.origin === window.location.origin) {
                clickCallback({ data: event.data.message });
            }
        };

        window.addEventListener('message', listener);

        return () => {
            window.removeEventListener('message', listener);
        };
    }, [clickCallback]);

    useEffect(() => {
        const iframe = document.getElementById('procedure_iframe');
        if (!onMouseMove || !iframe) return;

        const listener = event => {
            const rect = iframe.getBoundingClientRect();
            onMouseMove({ ...event, clientX: event.clientX + rect.x, clientY: event.clientY + rect.y });
        };

        iframe.contentDocument.addEventListener('mousemove', listener);

        return () => {
            iframe.contentDocument?.removeEventListener('mousemove', listener);
        };
    }, [onMouseMove]);

    useEffect(() => {
        const iframe = document.getElementById('procedure_iframe');
        if (!onMouseClick || !iframe) return;

        iframe.contentDocument.addEventListener('click', onMouseClick);

        return () => {
            iframe.contentDocument?.removeEventListener('click', onMouseClick);
        };
    }, [onMouseClick]);

    return (
        <iframe title="procedure_iframe" id="procedure_iframe" frameBorder="no" className="procedure-content"></iframe>
    );
};

export default HtmlIFrame;

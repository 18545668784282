import React from 'react';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import useImportJobDetails, { IMPORT_FILTER } from './useImportJobDetails';
import Table from 'components/Shared/Table/Table';
import SmallSpinner from 'components/SmallSpinner';
import Confirmation from 'components/Shared/Confirmation/Confirmation';
import InfiniteScrollingFooter from '../shared/InfiniteScrollingFooter';
import { Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import ImportJobBookCommandModal from 'components/Modals/ImportBookCommand/ImportJobBookCommandModal';
import RetryImportJobModal from 'components/Modals/RetryImportJobModal/RetryImportJobModal';

const ImportJobDetails: React.FC = () => {
    const {
        headers,
        handleSearchValueChange,
        handleSearchKeyDown,
        handleSearchBlur,
        searchValue,
        handlers,
        data,
        loading,
        totalBooks,
        refreshReportOem,
        refreshReportDate,
        confirmationRef,
        fetchMore,
        importFilter,
        showImportJobBookCommandModal,
        onSerializedImportJobBookCommandModalClose,
        importJobBookCommandId,
        handleImportFilterChange,
        toggles,
        retryImportModalOpen,
        onRetryImportModalOpen,
        onRetryImportModalClose,
        handleToggleChange,
        handleRunButtonClick,
        canRunRetryImportJob,
    } = useImportJobDetails();

    return (
        <div>
            <Confirmation ref={confirmationRef} />
            {showImportJobBookCommandModal && (
                <ImportJobBookCommandModal
                    open={showImportJobBookCommandModal}
                    title={'Import Job Book Command'}
                    onSerializedImportJobBookCommandModalClose={onSerializedImportJobBookCommandModalClose}
                    importJobBookCommandId={importJobBookCommandId}
                />
            )}
            <div className="d-flex flex-row align-items-center">
                <div className="mx-4 font-weight-bold">{`${refreshReportOem?.oemName} Refresh Report From ${refreshReportDate}`}</div>
                <div style={{ flexGrow: 1 }}>
                    <SearchBar
                        id="import-manager-details-search"
                        value={searchValue}
                        disabled={loading}
                        onChange={handleSearchValueChange}
                        onKeyDown={handleSearchKeyDown}
                        onBlur={handleSearchBlur}
                        className="px-2 py-3"
                    />
                </div>
                {canRunRetryImportJob && (
                    <div className="mx-4">
                        <Button onClick={onRetryImportModalOpen}>Retry Import Job</Button>
                        <RetryImportJobModal
                            isOpen={retryImportModalOpen}
                            onClose={onRetryImportModalClose}
                            toggles={toggles}
                            onToggleChange={handleToggleChange}
                            onRunButtonClick={handleRunButtonClick}
                        />
                    </div>
                )}
                <div id="import-manager-details-success-filter" className="mx-4">
                    <ToggleButtonGroup
                        type="radio"
                        value={importFilter}
                        onChange={handleImportFilterChange}
                        name="stateFilter">
                        <ToggleButton
                            value={IMPORT_FILTER.All}
                            id={IMPORT_FILTER.All}
                            disabled={loading}
                            variant={importFilter === IMPORT_FILTER.All ? 'primary' : 'outline-primary'}>
                            All
                        </ToggleButton>
                        <ToggleButton
                            value={IMPORT_FILTER.Successful}
                            id={IMPORT_FILTER.Successful}
                            disabled={loading}
                            variant={importFilter === IMPORT_FILTER.Successful ? 'primary' : 'outline-primary'}>
                            Successful
                        </ToggleButton>
                        <ToggleButton
                            value={IMPORT_FILTER.Failed}
                            id={IMPORT_FILTER.Failed}
                            disabled={loading}
                            variant={importFilter === IMPORT_FILTER.Failed ? 'primary' : 'outline-primary'}>
                            Failed
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
            <Table
                className="mb-5"
                headers={headers}
                data={data}
                idKey="importJobBookId"
                cellEventCallbacks={handlers}
                noDataMessage="No Import Job Book records are available for this import job"
                tableHtmlId="import-manager-details"
            />
            <InfiniteScrollingFooter loading={loading} fetchMore={fetchMore} hasMoreData={totalBooks > data.length} />
            <footer className="d-flex flex-row fixed-bottom" style={{ minHeight: '35px' }}>
                {!loading ? (
                    <div className="m-2">{`Showing ${data.length} of ${totalBooks} items`}</div>
                ) : (
                    <div className="m-2">
                        <SmallSpinner id="loading-spinner" />
                    </div>
                )}
            </footer>
        </div>
    );
};

export default ImportJobDetails;

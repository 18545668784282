import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/Shared/Dropdown/Dropdown';

const Breadcrumb = ({ isShown, isCurrent, link, text, links, icon, iconOnClick }) => {
    let navigate = useNavigate();

    if (!isShown) return null;

    return (
        <li className={`breadcrumb-item ${isCurrent ? 'active' : ''}`} style={{ whiteSpace: 'nowrap' }}>
            {isCurrent ? (
                links && links.length > 0 ? (
                    <Dropdown
                        text={text}
                        active={links.find(l => l.text === text)}
                        items={links}
                        dropdownClass="top-menu"
                    />
                ) : (
                    <div className="d-inline-block">
                        {text}{' '}
                        {icon && (
                            <FontAwesomeIcon
                                data-testid="clock-icon"
                                className="clickable"
                                icon={icon}
                                onClick={iconOnClick}
                            />
                        )}
                    </div>
                )
            ) : (
                <div className="text-white clickable d-inline-block" onClick={() => navigate(link)}>
                    {text}{' '}
                    {icon && (
                        <FontAwesomeIcon
                            data-testid="clock-icon"
                            className="clickable"
                            icon={icon}
                            onClick={iconOnClick}
                        />
                    )}
                </div>
            )}
        </li>
    );
};

export default Breadcrumb;

import { isNil } from 'lodash';
import { Operator, OPERATOR } from './Operators';
import { PropertyType, PROPERTY_TYPE } from './PropertyType';

export type ProcedurePropertyMetadata<TProperty = unknown, TValue = TProperty> = {
    id: string;
    name: string;
    displayName: string;
    type: PropertyType<TValue>;
    enabled: boolean;
    searchable: boolean;
    sortable: boolean;
    ruleable: boolean;
    rulePropertyName: string;
    ruleableOperators: Operator<TValue>[];
    multiple: boolean;
    valueProvider: (v: TProperty) => TValue;
};

export const buildProcedureProperty = <TProperty = unknown, TValue = TProperty>({
    id,
    name,
    displayName,
    type,
    enabled,
    searchable,
    sortable,
    ruleable,
    rulePropertyName,
    ruleableOperators,
    multiple,
    valueProvider,
}: {
    id?: string;
    name: string;
    displayName?: string;
    type?: PropertyType<TValue>;
    enabled?: boolean;
    searchable?: boolean;
    sortable?: boolean;
    ruleable?: boolean;
    rulePropertyName?: string;
    ruleableOperators?: Operator<TValue>[];
    multiple?: boolean;
    valueProvider?: (v: TProperty) => TValue;
}): ProcedurePropertyMetadata<TProperty, TValue> => {
    enabled = enabled ?? true;
    ruleable = ruleable ?? !isNil(rulePropertyName);

    return {
        id: id ?? name,
        name,
        displayName: displayName ?? name,
        type: type ?? (PROPERTY_TYPE.string as unknown as PropertyType<TValue>),
        enabled,
        searchable: enabled && (searchable ?? true),
        sortable: enabled && (sortable ?? true),
        ruleable,
        rulePropertyName: ruleable ? rulePropertyName ?? name : null,
        ruleableOperators: ruleable ? ruleableOperators ?? [OPERATOR.eq] : [],
        multiple: multiple ?? false,
        valueProvider: valueProvider ?? (v => v as unknown as TValue),
    };
};

import Breadcrumb from './Breadcrumb';
import { useState, useEffect, useContext } from 'react';
import { ToastContext } from 'components/ToastProvider';
import { requestModelsForOem } from 'api/vehicleInfo';

const ModelNameBreadcrumb = ({ oemId, modelId, isCurrent, baseUrl }) => {
    const [models, setModels] = useState([]);
    const [modelName, setModelName] = useState('');
    const [links, setLinks] = useState([]);
    const { showToast } = useContext(ToastContext);

    useEffect(() => {
        (async () => {
            if (oemId) {
                const intOemId = parseInt(oemId);
                if (intOemId) {
                    try {
                        const oemModels = await requestModelsForOem(intOemId);
                        oemModels.sort((m1, m2) => {
                            if (m1.modelName < m2.modelName) return -1;
                            if (m1.modelName > m2.modelName) return 1;
                            return 0;
                        });
                        const oemlinks = oemModels.map(m => ({
                            id: m.modelId,
                            text: m.modelName,
                            link: `${baseUrl}/${intOemId}/${m.modelId}`,
                        }));
                        setModels(oemModels);
                        setLinks(oemlinks);
                    } catch (error) {
                        showToast(error);
                    }
                }
            }
        })();
    }, [oemId, baseUrl, showToast]);

    useEffect(() => {
        const currentModel = models.find(m => m.modelId === parseInt(modelId));
        if (currentModel) {
            setModelName(currentModel.modelName);
        }
    }, [modelId, models]);

    return <Breadcrumb isShown={true} isCurrent={isCurrent} links={links} text={modelName} />;
};

export default ModelNameBreadcrumb;

import GroupListNamesComponent from './Helpers/Components/GroupListNamesComponent';
import GroupListIdsComponent from './Helpers/Components/GroupListIdsComponent';

const proceduresOperatorsList = {
    containsAny: {
        value: 'containsAny',
        label: 'contains any',
        buildFilter: (propertyName, value) =>
            `${propertyName.replaceAll('.', '/')}/any(g: g/groupId in (${value.map(g => g.regionId).join(', ')}))`,
    },
    notContainsAny: {
        value: 'notContainsAny',
        label: 'not contains',
        buildFilter: (propertyName, value) =>
            `${propertyName.replaceAll('.', '/')}/all(g: g/groupId in (${value
                .map(g => g.regionId)
                .join(', ')}) eq false)`,
    },
    containsAll: {
        value: 'containsAll',
        label: 'contains all',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(g => `${propertyName.replaceAll('.', '/')}/any(g: g/groupId eq ${g.regionId})`)
                .join(' and ')})`,
    },
    eqwo: {
        value: 'eqwo',
        label: 'equal without order',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(g => `${propertyName.replaceAll('.', '/')}/any(g: g/groupId eq ${g.regionId})`)
                .join(' and ')} and ${propertyName.replaceAll('.', '/')}/$count eq ${value.length})`,
    },
    nameContains: {
        value: 'nameContains',
        label: 'name contains',
        buildFilter: (propertyName, value) =>
            `${propertyName.replaceAll('.', '/')}/any(g: g/groupId in (${value.map(g => g.regionId).join(', ')}))`,
    },
};

const proceduresGroupOperatorsIds = [
    proceduresOperatorsList.containsAny,
    proceduresOperatorsList.containsAll,
    proceduresOperatorsList.eqwo,
];

const proceduresGroupOperatorsNames = [
    proceduresOperatorsList.nameContains,
    proceduresOperatorsList.containsAny,
    proceduresOperatorsList.containsAll,
    proceduresOperatorsList.notContainsAny,
    proceduresOperatorsList.eqwo,
];

const mappingRulesOperatorsList = {
    containsAny: {
        value: 'containsAny',
        label: 'contains any',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' or ')})`,
    },
    notContainsAny: {
        value: 'notContainsAny',
        label: 'not contains',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `not(contains(${propertyName.replaceAll('.', '/')}, ',${g},'))`)
                .join(' and ')})`,
    },
    containsAll: {
        value: 'containsAll',
        label: 'contains all',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' and ')})`,
    },
    eqwo: {
        value: 'eqwo',
        label: 'equal without order',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' and ')} and length(${propertyName.replaceAll('.', '/')}) eq ${Math.max(
                2 * value.length + 1,
                0
            )})`,
    },
    nameContains: {
        value: 'nameContains',
        label: 'name contains',
        buildFilter: (propertyName, value) =>
            `(${value
                .map(v => v.regionId)
                .map(g => `contains(${propertyName.replaceAll('.', '/')}, ',${g},')`)
                .join(' or ')})`,
    },
};

const mappingRulesGroupOperatorsNames = [
    mappingRulesOperatorsList.nameContains,
    mappingRulesOperatorsList.containsAny,
    mappingRulesOperatorsList.containsAll,
    mappingRulesOperatorsList.notContainsAny,
    mappingRulesOperatorsList.eqwo,
];

export default (isIds: boolean, isProcedures: boolean) => ({
    component: isIds ? GroupListIdsComponent : GroupListNamesComponent,
    format: v => v,
    operators: isProcedures
        ? isIds
            ? proceduresGroupOperatorsIds
            : proceduresGroupOperatorsNames
        : mappingRulesGroupOperatorsNames,
    defaultValue: '',
    allowFalse: false,
    allowInstances: 1,
    validator: v => v.length > 0,
});

import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import NavigationTile from 'components/Shared/Components/NavigationTile';
import React, { useEffect, useState, useContext } from 'react';
import { requestNewVehicleCounts } from 'api/RepairProcedureApi';

const ToolSelection = ({ tools }) => {
    const { hasAccess } = useContext(AccessControlContext);

    const [notificationCount, setNotificationCount] = useState(0);

    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const counts = await requestNewVehicleCounts();
                setNotificationCount(counts.total);
            } catch (error) {
                console.error('Failed to fetch total new vehicle counts', error);
            }
        };

        fetchCounts();
    }, []);

    return (
        <div className="container mt-4">
            <div className="row">
                {tools.map((t, i) => (
                    <NavigationTile
                        key={i}
                        path={t.path}
                        text={t.text}
                        icon={t.icon}
                        tileDescription={t.tileDescription}
                        display={hasAccess(t.access)}
                        notificationCount={t.text === 'Ingestion Manager' ? notificationCount : 0}
                    />
                ))}
            </div>
        </div>
    );
};

export default ToolSelection;

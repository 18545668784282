import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SearchBar.scss';

const SearchBar = ({
    value,
    disabled,
    onChange,
    onKeyDown,
    onBlur,
    className,
    id,
}: {
    value: string;
    disabled?: boolean;
    onChange: (e: string) => void;
    onKeyDown?: (e?: React.KeyboardEvent<HTMLInputElement>) => void;
    onBlur?: (e?: React.FocusEvent<HTMLInputElement, Element>) => void;
    className?: string;
    id?: string;
}) => {
    const handleClearButtonClick = React.useCallback(() => {
        onChange('');
        onBlur && onBlur();
    }, [onChange, onBlur]);

    const inputId = `${id}-input`;
    const buttonId = `${id}-button`;

    return (
        <div id={id} className={`search-bar ${className ? className : 'w-50 px-2 py-3'}`}>
            <div className="input-group col-auto">
                <span className="input-group-text">
                    <FontAwesomeIcon className="text-primary m-1" icon="search" />
                </span>
                <input
                    id={inputId}
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    disabled={disabled ?? false}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                />
                <div className="input-group-append">
                    <button
                        id={buttonId}
                        className="btn btn-outline-secondary"
                        type="button"
                        disabled={disabled ?? false}
                        onClick={handleClearButtonClick}>
                        <strong>×</strong>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;

import {
    requestFlagTerms,
    requestCreateFlagTerm,
    requestRunFlagTerm,
    requestUndoFlagTerm,
    requestDeleteFlagTerm,
    requestSetActiveFlagTerm,
    requestNumberOfFlags,
} from 'api/FlaggerApi';

import { TABLE_COLUMN_ENUM } from 'helpers/BulkEditTableHelper';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from 'components/Layout';

const useFlagTerms = closeModal => {
    const navigate = useNavigate();
    const { oemId } = useParams();
    const { notifications } = useContext(NotificationsContext);
    const [flagTerms, setFlagTerms] = useState([]);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);

    const runFlagAction = useCallback(
        async (actionCallback, id, { successMessage, successAction }) => {
            try {
                if (oemId === '100') {
                    throw { message: 'It is not possible to run flag term for OEMiQ (test) books' };
                }

                await actionCallback(oemId, id);
                successMessage && notifications.pushSuccess(successMessage);
                successAction && successAction(id);
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                closeModal();
            }
        },
        [closeModal, notifications, oemId]
    );

    const navigateToTagger = useCallback(
        (oemId, term) => {
            const filters = [
                {
                    columnId: TABLE_COLUMN_ENUM.ONE_TIME_USE_FLAG_TERM_ID,
                    operator: 3,
                    value: term.id,
                },
            ];

            navigate(`/tagging-process/tagger/${oemId}/bulkedit`, { state: filters });
        },
        [navigate]
    );

    const createFlagTerm = useCallback(
        async newFlagTermData => {
            try {
                const { id } = await requestCreateFlagTerm(oemId, newFlagTermData);
                setFlagTerms(v => [
                    {
                        id,
                        oemId,
                        numberOfFlags: 0,
                        ...newFlagTermData,
                    },
                    ...v,
                ]);
                notifications.pushSuccess('Flag term has been added');
            } catch (e) {
                notifications.pushExceptionDanger(e);
            } finally {
                closeModal();
            }
        },
        [closeModal, notifications, oemId]
    );

    const runFlagTerm = useCallback(
        id => runFlagAction(requestRunFlagTerm, id, { successMessage: 'Flag term has been added to queue' }),
        [runFlagAction]
    );
    const undoFlagTerm = useCallback(
        id =>
            runFlagAction(requestUndoFlagTerm, id, {
                successMessage: 'Flag term run has been undone',
                successAction: getFlagTerms,
            }),
        [runFlagAction, getFlagTerms]
    );
    const deleteFlagTerm = useCallback(
        id =>
            runFlagAction(requestDeleteFlagTerm, id, {
                successAction: id => setFlagTerms(term => term.filter(t => t.id !== id)),
                successMessage: 'Flag term has been deleted',
            }),
        [runFlagAction]
    );
    const activateFlagTerm = useCallback(
        id => {
            const toggleActive = toggleParams => {
                setFlagTerms(prev => [
                    ...prev.map(term =>
                        term.id !== toggleParams.termId ? term : { ...term, isActive: toggleParams.isActive }
                    ),
                ]);
            };
            const executeSetActiveFlagTerm = async (oemId, toggleParams) => {
                await requestSetActiveFlagTerm(oemId, toggleParams);
            };
            runFlagAction(executeSetActiveFlagTerm, id, {
                successMessage: 'Status has been updated',
                successAction: toggleActive,
            });
        },
        [runFlagAction]
    );

    const getFlagTerms = useCallback(async () => {
        try {
            incrementLoading();
            const flagTermsResp = await requestFlagTerms(oemId);
            setFlagTerms(flagTermsResp);
        } catch (e) {
            notifications.pushExceptionDanger(e);
        } finally {
            decrementLoading();
        }
    }, [notifications, oemId, incrementLoading, decrementLoading]);

    useEffect(() => {
        getFlagTerms();
    }, [getFlagTerms]);

    const getNumberOfFlags = useCallback(
        async id => {
            try {
                return await requestNumberOfFlags(id);
            } catch (error) {
                notifications.pushExceptionDanger(error);
            }
        },
        [notifications]
    );

    return {
        flagTerms,
        navigateToTagger,
        createFlagTerm,
        runFlagTerm,
        undoFlagTerm,
        deleteFlagTerm,
        activateFlagTerm,
        getNumberOfFlags,
    };
};

export default useFlagTerms;

import { OemId } from 'helpers/OemId';
import { RequestRestGetWithOdataParams } from '../RequestRestGetWithOdataParams';
import {
    RpFordProcedure,
    RpGMProcedure,
    RpHondaProcedure,
    RpNissanProcedure,
    RpOemIqFordProcedure,
    RpStellantisProcedure,
    RpSubaruProcedure,
    RpToyotaProcedure,
    RpVWProcedure,
    RpHyundaiProcedure,
} from './OemProcedureTypes';
import { OdataResponse } from './types';

export class OemProceduresApiService<TProcedure> {
    private readonly oemIds: number[];
    readonly expandClause: string;
    readonly oemMetadataProperty: string;
    // TODO: Filter `isTestOem` excluding test procedures (includes one test books) should be remove, but first data in OemId != 100 needs to be cleaned
    readonly isTestOem: boolean;
    readonly filterClause: string;

    readonly defaultOrderBy = 'ProcedureId desc';
    readonly selectClause =
        'IsDeleted,ProcedureTitle,MappingRuleId,ProcedureId,OemId,UpdateDate,StageArea,StageArea/Type,StageArea/Groups';
    readonly proceduresRoute = '/api/RepairProcedure/odata/Procedure';
    readonly TestBookFilter = 'BooksForProcedure/all(b: not b/Book/IsTestBook)';
    readonly ProceduresExpand = 'BooksForProcedure($select=Book;$expand=Book($select=BookId,BookName,IsTestBook))';

    constructor({
        oemIds,
        oemMetadataProperty,
        isTestOem,
    }: {
        oemIds: number[];
        oemMetadataProperty: string;
        isTestOem?: boolean;
    }) {
        this.oemIds = oemIds;
        this.oemMetadataProperty = oemMetadataProperty;
        this.expandClause = oemMetadataProperty + ',' + this.ProceduresExpand;
        this.isTestOem = isTestOem || false;

        this.filterClause = '(' + oemIds.map(oemId => `oemId eq ${oemId}`).join(' or ') + ')';

        if (!this.isTestOem) {
            this.filterClause += ' and ' + this.TestBookFilter;
        }
    }

    buildFilter = (filter: string | null): string =>
        filter ? `${filter} and ${this.filterClause}` : this.filterClause;

    countProcedures = async (filter: string | null, includeDeletedProcedures: boolean): Promise<number> => {
        filter = this.buildFilter(filter);
        const oDataParams = { filter, top: 0, count: true };
        const result = await RequestRestGetWithOdataParams(
            this.proceduresRoute,
            oDataParams,
            null,
            includeDeletedProcedures === true
                ? {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'x-client': 'none',
                  }
                : null
        );
        return result['@odata.count'];
    };

    getProcedures = async (
        filter: string | null,
        orderBy: string,
        top: number,
        skip: number,
        includeDeletedProcedures: boolean
    ): Promise<OdataResponse<TProcedure[]>> => {
        const result = await RequestRestGetWithOdataParams(
            this.proceduresRoute,
            {
                select: this.selectClause,
                filter: this.buildFilter(filter),
                orderBy: orderBy || this.defaultOrderBy,
                top,
                skip,
                expand: this.expandClause,
                count: false,
            },
            null,
            includeDeletedProcedures
                ? {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'x-client': 'none',
                  }
                : null
        );

        return result;
    };

    getProceduresStaleData = async (
        proceduresIds: number[]
    ): Promise<OdataResponse<{ procedureId: number; updateDate: string }[]>> =>
        await RequestRestGetWithOdataParams(this.proceduresRoute, {
            select: 'ProcedureId,UpdateDate',
            filter: `ProcedureId in (${proceduresIds.join(',')})`,
            count: false,
        });
}

export const FordProceduresApiService = new OemProceduresApiService<RpFordProcedure>({
    oemIds: [OemId.Ford],
    oemMetadataProperty: 'latestFordRawProcedure',
});

export const ToyotaProceduresApiService = new OemProceduresApiService<RpToyotaProcedure>({
    oemIds: [OemId.Toyota, OemId.Lexus],
    oemMetadataProperty: 'latestToyotaProcedure',
});

export const NissanProceduresApiService = new OemProceduresApiService<RpNissanProcedure>({
    oemIds: [OemId.Nissan, OemId.Infiniti],
    oemMetadataProperty: 'latestNissanProcedure',
});

export const GMProceduresApiService = new OemProceduresApiService<RpGMProcedure>({
    oemIds: [OemId.GMC],
    oemMetadataProperty: 'latestGMProcedure',
});

export const HondaProceduresApiService = new OemProceduresApiService<RpHondaProcedure>({
    oemIds: [OemId.Honda, OemId.Acura],
    oemMetadataProperty: 'latestHondaProcedure',
});

export const StellantisProceduresApiService = new OemProceduresApiService<RpStellantisProcedure>({
    oemIds: [OemId.Chrysler, OemId.RAM, OemId.Fiat, OemId.Dodge, OemId.Jeep, OemId.AlfaRomeo],
    oemMetadataProperty: 'latestStellantisProcedure',
});

export const VWProceduresApiService = new OemProceduresApiService<RpVWProcedure>({
    oemIds: [OemId.Volkswagen, OemId.Audi],
    oemMetadataProperty: 'latestVWProcedure',
});

export const SubaruProceduresApiService = new OemProceduresApiService<RpSubaruProcedure>({
    oemIds: [OemId.Subaru],
    oemMetadataProperty: 'latestSubaruProcedure',
});

export const HyundaiProceduresApiService = new OemProceduresApiService<RpHyundaiProcedure>({
    oemIds: [OemId.Hyundai, OemId.Genesis],
    oemMetadataProperty: 'latestHyundaiProcedure',
});

export const OemIqFordProceduresApiService = new OemProceduresApiService<RpOemIqFordProcedure>({
    oemIds: [OemId.OEMiQ],
    oemMetadataProperty: 'latestOemIqFordProcedure',
    isTestOem: true,
});

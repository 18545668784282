/**
 * Helper functions related to dates on the platform
 * ---------------------------------
 */

/**
 * Function for converting our DB dates into friendly names on front end.
 * @param {Date coming from DB} date
 */
export function FormatDateToHumanReadable(date) {
    let jsDate = new Date(date);
    let utcDate = new Date(
        Date.UTC(
            jsDate.getFullYear(),
            jsDate.getMonth(),
            jsDate.getDate(),
            jsDate.getHours(),
            jsDate.getMinutes(),
            jsDate.getSeconds()
        )
    );
    if (date.includes('Z')) utcDate = new Date(date);

    let formattedTime = FormatTime(utcDate);

    return `${FormatDate(utcDate, new Date())} ${formattedTime}`;
}

const FormatDate = (date, currentDate) => {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    date.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear(),
        currentMonth = '' + (currentDate.getMonth() + 1),
        currentDay = '' + currentDate.getDate(),
        currentYear = currentDate.getFullYear(),
        diffTime = Math.abs(date - currentDate),
        diffDays = diffTime / (1000 * 60 * 60 * 24);

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (currentMonth.length < 2) currentMonth = '0' + currentMonth;
    if (currentDay.length < 2) currentDay = '0' + currentDay;

    if (`${month}${day}${year}` === `${currentMonth}${currentDay}${currentYear}`) return 'Today';
    else if (diffDays === 1) return 'Yesterday';
    else if (diffDays < 7) return days[date.getDay()];

    return [month, day].join('/');
};

const FormatTime = date => {
    let hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours(),
        minutes = date.getMinutes(),
        tod = date.getHours() >= 12 ? 'PM' : 'AM';

    if (minutes < 10) minutes = '0' + minutes;

    return `${[hour, minutes].join(':')} ${tod}`;
};

export const FormatDateToGMT = dateStr => {
    const date = new Date(dateStr);
    return `${date.toISOString().replace('T', ' ').substring(0, 19)} GMT`;
};

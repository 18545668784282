export const TagStatus = Object.freeze({
    1: { text: 'In Review', color: 'text-bg-primary' },
    2: { text: 'Complete', color: 'text-bg-success' },
    3: { text: 'Needs Help', color: 'text-bg-danger' },
    4: { text: 'In Progress', color: 'text-bg-warning' },
});

export const colors = [
    '#e57373',
    '#F06292',
    '#BA68C8',
    '#9575CD',
    '#7986CB',
    '#64B5F6',
    '#4FC3F7',
    '#4DD0E1',
    '#4DB6AC',
    '#81C784',
    '#AED581',
    '#DCE775',
    '#FFF176',
    '#FFD54F',
    '#FFB74D',
    '#FF8A65',
    '#A1887F',
    '#E0E0E0',
    '#90A4AE',
];

export const validatePath = (path, validIds) => {
    //A valid path should always have an id
    if (path.indexOf('#') === -1) return false;

    //if there is anything after the id, substring it out
    let rootId = path.split('#')[1];
    if (rootId.indexOf(' ')) rootId = rootId.substring(0, rootId.indexOf(' '));

    //check to see if the id is contained in the child elements of the procedure
    if (validIds.has(rootId)) return true;
    else return false;
};

const getChildIds = (el, idArr) => {
    if (!(el instanceof Element)) return idArr;
    if (el.id) {
        idArr.add(el.id);
    }
    if (el.children) {
        for (let i = 0; i < el.children.length; i++) {
            idArr = new Set([...getChildIds(el.children[i], idArr), ...idArr]);
        }
    }
    return idArr;
};

export const getValidIds = procedureId => {
    let validIds = new Set();

    const el = document.getElementById(`procedure-${procedureId}`);
    if (!el) return validIds;

    return getChildIds(el, validIds);
};

export const getCssPath = el => {
    if (!(el instanceof Element)) return;
    let path = [];
    // jump over inline HTML nodes (<b><b/>, <i></i>, and possibly <span></span> )
    while ((el.parentNode && el.nodeName === 'B') || el.nodeName === 'I') {
        el = el.parentNode;
    }
    // build CSS path for HTML element
    while (el.nodeType === Node.ELEMENT_NODE) {
        let selector = el.nodeName.toLowerCase();
        if (el.id) {
            selector += '[id="' + el.id + '"]';
            path.unshift(selector);
            break;
        } else {
            let sib = el,
                nth = 1;
            while ((sib = sib.previousElementSibling)) {
                if (sib.nodeName.toLowerCase() === selector) nth++;
            }
            if (nth !== 1) selector += ':nth-of-type(' + nth + ')';
        }
        path.unshift(selector);
        el = el.parentNode;
    }
    return path.join(' > ');
};

export const escapeSpecialIdCharacters = id => {
    if (!id || id.indexOf('#')) return id;
    const idStrings = id.split('#');

    const selectorCombinators = [' ', '>', '~', '+'];
    for (let j = 0; j < idStrings.length; j++) {
        let idString = idStrings[j];
        if (idString.length === 0) continue;
        let nextCssChunkIdx = 0;
        for (let i = 0; i < idString.length; i++) {
            if (selectorCombinators.includes(idString.charAt(i))) {
                nextCssChunkIdx = i;
                break;
            }
        }
        let idText = idString.substring(0, nextCssChunkIdx);
        let rest = idString.substring(nextCssChunkIdx);
        idText = idText.replaceAll('/', '\\/').replaceAll('[', '\\[').replaceAll(']', '\\]');
        idStrings[j] = idText + rest;
    }
    return idStrings.join('#');
};

export const clearHighlight = () => {
    const highlight = document.getElementById('highlight');

    if (!highlight) return;

    highlight.style.left = '0';
    highlight.style.top = '0';
    highlight.style.width = '0';
    highlight.style.height = '0';
};

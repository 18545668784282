import React, { useState } from 'react';
import { ReactModal } from 'oemiq-common';
import 'components/locations/ManageCustomers/CustomerToolsStyles.scss';
import { OEC_STAGING_ORG, ORG_CANCEL_BUTTON_ID } from '../../ManageCustomersConstants';
import { ClearableDropDown } from 'components/Shared';
import { IMergeOrganizationModal, IOrganization } from '../../types';

const MergeOrganizationModal = ({
    orgId,
    isOpen,
    toggle,
    organizations,
    openMergeOrgConfirmationModal,
}: IMergeOrganizationModal) => {
    const [selectedProposedOrgId, setSelectedProposedOrgId] = useState<number>(-1);
    return (
        <ReactModal
            id="merge-org"
            headerComponent={<div>Merge Organization</div>}
            isOpen={isOpen}
            backdrop="static"
            toggle={toggle}
            bodyComponent={
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                        <span>
                            <b>Current Organization:</b>
                        </span>
                        <span id="current-merge-org">
                            {organizations.find((org: IOrganization) => org.organizationId === orgId).name}
                        </span>
                    </div>
                    <div className="d-flex flex-column mt-5">
                        <span>
                            <b>New Parent Organization</b>
                        </span>
                        <ClearableDropDown
                            id="proposedOrganization"
                            value={selectedProposedOrgId}
                            onChange={(e: Event) => {
                                setSelectedProposedOrgId(parseInt((e.target as HTMLInputElement).value));
                            }}
                            defaultOption={{ value: -1, text: 'Select' }}
                            options={organizations
                                .filter(
                                    (organization: IOrganization) =>
                                        organization.organizationId !== orgId &&
                                        organization.name !== OEC_STAGING_ORG.name &&
                                        organization.isActive
                                )
                                .map((organization: IOrganization) => {
                                    return { value: organization.organizationId, text: organization.name };
                                })}
                            className="form-control mt-2"
                            fullWidth
                            onClear={() => setSelectedProposedOrgId(-1)}
                        />
                    </div>
                </div>
            }
            footerComponent={
                <div>
                    <button
                        type="button"
                        className="btn btn-danger h-100"
                        id={ORG_CANCEL_BUTTON_ID}
                        onClick={() => toggle(null)}>
                        Cancel
                    </button>
                    <button
                        id="merge-original-org"
                        type="button"
                        className="btn btn-primary ms-3 h-100"
                        onClick={() => openMergeOrgConfirmationModal(orgId, selectedProposedOrgId)}
                        disabled={selectedProposedOrgId === -1}>
                        Merge
                    </button>
                </div>
            }
        />
    );
};

export default MergeOrganizationModal;

const OEC_STAGING_ORG = Object.freeze({ name: 'OEC Client Staging', organizationId: 4 });

const ORG_CANCEL_BUTTON_ID = 'org-cancel-button';
const COMPANY_CANCEL_BUTTON_ID = 'company-cancel-button';
const USER_CANCEL_BUTTON_ID = 'user-cancel-button';
const FORCE_CLOSE_EVENT_ID = 'force_close_event';
const CONDITIONAL_CLOSE_EVENT_ID = 'conditional_close_event';

const FORCE_CLOSE_EVENT = {
    currentTarget: {
        id: `${FORCE_CLOSE_EVENT_ID}`,
    },
};
const CONDITIONAL_CLOSE_EVENT = {
    currentTarget: {
        id: `${CONDITIONAL_CLOSE_EVENT_ID}`,
    },
};

const COMMON_OVERRIDE_IDs = [
    ORG_CANCEL_BUTTON_ID,
    COMPANY_CANCEL_BUTTON_ID,
    USER_CANCEL_BUTTON_ID,
    FORCE_CLOSE_EVENT_ID,
    '×' /* The x character rendered in the modal's native close button */,
];

const GetCloseEventOverride = (e, customOverrideIds = []) => {
    if (!e) return false;

    let override = false;
    const overrideEventIds = [...COMMON_OVERRIDE_IDs, ...customOverrideIds];
    const eventId = !e.currentTarget.id ? e.currentTarget.innerText : e.currentTarget.id;

    if (overrideEventIds.includes(eventId)) override = true;
    if (customOverrideIds.includes(eventId)) e.preventDefault();

    return override;
};

//#region Customer Management Navigation Constants

/* terms:
    name -> display name for tool
    route -> relative path for tool
    terminus -> (optional) singular name for tool (e.g. "organization" for "organizations")
*/
const CUST_TOOLS = {
    baseTool: { name: 'Manage Customers', route: 'manage-customers' },
    networkTool: { name: 'Networks', route: 'networks', terminus: 'network' },
    orgTool: { name: 'Organizations', route: 'organizations', terminus: 'organization' },
    orgUserTool: {
        name: 'Organization Users',
        route: 'users',
        terminus: 'user' /* note: no "user" page implemented */,
    },
    compTool: { name: 'Locations', route: 'locations', terminus: 'location' },
    compUserTool: { name: 'Users', route: 'users', terminus: 'user' /* note: no "user" page implemented */ },
};

const CUST_TOOL_NAV = {
    [CUST_TOOLS.baseTool.name]: {
        self: CUST_TOOLS.baseTool,
        children: [CUST_TOOLS.orgTool, CUST_TOOLS.networkTool],
        parent: null,
    },
    [CUST_TOOLS.networkTool.name]: {
        self: CUST_TOOLS.networkTool,
        children: [],
        parent: CUST_TOOLS.baseTool,
    },
    [CUST_TOOLS.orgTool.name]: {
        self: CUST_TOOLS.orgTool,
        children: [CUST_TOOLS.compTool],
        parent: CUST_TOOLS.baseTool,
    },
    [CUST_TOOLS.orgUserTool.name]: {
        self: CUST_TOOLS.orgUserTool,
        children: [],
        parent: CUST_TOOLS.orgTool,
    },
    [CUST_TOOLS.compTool.name]: {
        self: CUST_TOOLS.compTool,
        children: [CUST_TOOLS.compUserTool],
        parent: CUST_TOOLS.orgTool,
    },
    [CUST_TOOLS.compUserTool.name]: {
        self: CUST_TOOLS.compUserTool,
        children: [],
        parent: CUST_TOOLS.compTool,
    },

    // redirect on unknown / invalid
    default: {
        self: CUST_TOOLS.baseTool,
        children: [CUST_TOOLS.orgTool, CUST_TOOLS.networkTool],
        parent: null,
    },
};

CUST_TOOLS.Path = (tool, ...nestedIds) => {
    let toolNode = CUST_TOOL_NAV[tool?.name];
    if (!toolNode) return '';

    const addNodePath = (path, toolNode, nestedIds = null) =>
        `/${
            toolNode.children.length > 0 && nestedIds && nestedIds.length > 0
                ? /* selection indicated */
                  `${toolNode.self.route}/${nestedIds.pop()}`
                : `${toolNode.self.route}`
        }${path ? `${path}` : ''}`;

    let relPath = addNodePath(null, toolNode, nestedIds);

    while (toolNode.parent !== null) {
        toolNode = CUST_TOOL_NAV[toolNode.parent.name];
        relPath = addNodePath(relPath, toolNode, nestedIds);
    }

    return relPath;
};

Object.values(CUST_TOOLS).forEach(tool => {
    tool.Path = (...args) => CUST_TOOLS.Path(tool, ...args);
});

// route <-> name
const CUST_DICT = Object.values(CUST_TOOLS).reduce((acc, tool) => {
    acc[tool.route] = tool.name;
    acc[tool.name] = tool.route;
    return acc;
}, {});

Object.freeze(CUST_TOOLS);
Object.freeze(CUST_TOOL_NAV);
Object.values(CUST_TOOL_NAV).forEach(Object.freeze);
Object.values(CUST_TOOLS).forEach(Object.freeze);
Object.freeze(CUST_DICT);

const BREADCRUMB_DELIMITER = ' > ';

//#endregion

export {
    CUST_TOOLS,
    CUST_TOOL_NAV,
    CUST_DICT,
    BREADCRUMB_DELIMITER,
    OEC_STAGING_ORG,
    ORG_CANCEL_BUTTON_ID,
    COMPANY_CANCEL_BUTTON_ID,
    USER_CANCEL_BUTTON_ID,
    FORCE_CLOSE_EVENT,
    CONDITIONAL_CLOSE_EVENT,
    GetCloseEventOverride,
};

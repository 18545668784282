import { useState, useCallback, useContext, useMemo } from 'react';
import { requestUpdateFlagDisposition } from 'api/RepairProcedureApi';
import useOneTimeUseFlagDispositions from 'hooks/useOneTimeUseFlagDispositions';
import { ToastContext } from 'components/ToastProvider';
import { TaggerToolContext } from '../TaggerTool';
import { LoadingContext } from 'components/Layout';

const TAG_KEYS = {
    ID: 'stagedOneTimeUseTagId',
    STYLES: 'highlightStylesTags',
};
const FLAG_KEYS = {
    ID: 'oneTimeUseFlagId',
    STYLES: 'highlightStylesFlags',
};

export const FLAG_TAG_ITEM_TYPES = Object.freeze({
    TAG: 1,
    FLAG: 2,
});

const useTagsAndFlagsDisplay = (tags, flags, highlightStyles) => {
    const [flagDispositionSelection, setFlagDispositionSelection] = useState(null);
    const [dispositionSelection, setDispositionSelection] = useState('');

    const oneTimeUseFlagDispositionTypes = useOneTimeUseFlagDispositions();
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    const { setFlags } = useContext(TaggerToolContext);

    const flagAndTagListItems = useMemo(() => {
        const newFlagAndTagListItems = [];
        const tagCopy = [...tags];
        const flagCopy = [...flags];
        const getStyleTop = (tagOrFlag, keys) => {
            const highlight = highlightStyles[keys.STYLES].find(s => s.id === tagOrFlag[keys.ID]);
            return highlight?.style ? parseInt(highlight.style.top, 10) : 0;
        };

        tagCopy.forEach(t => {
            newFlagAndTagListItems.push({
                key: `t:${t.stagedOneTimeUseTagId}`,
                type: FLAG_TAG_ITEM_TYPES.TAG,
                data: t,
                disabled: !t.isActive,
                sortIndex: getStyleTop(t, TAG_KEYS),
            });
        });
        flagCopy.forEach(f => {
            newFlagAndTagListItems.push({
                key: `f:${f.oneTimeUseFlagId}`,
                type: FLAG_TAG_ITEM_TYPES.FLAG,
                data: f,
                disabled: !!f.flagDisposition,
                sortIndex: getStyleTop(f, FLAG_KEYS),
            });
        });

        return newFlagAndTagListItems.sort((a, b) => !b.disabled - !a.disabled || a.sortIndex - b.sortIndex);
    }, [tags, flags, highlightStyles]);

    const handleFlagDispositionClick = (e, flag) => {
        e.preventDefault();
        e.stopPropagation();
        setFlagDispositionSelection(flag);
    };

    const handleDispositionModalToggle = useCallback(() => {
        setDispositionSelection('');
        setFlagDispositionSelection(null);
    }, []);

    const handleDispositionValueChange = useCallback(value => setDispositionSelection(value), []);

    const handleSaveFlagDispositionClick = useCallback(async () => {
        if (flagDispositionSelection) {
            try {
                incrementLoading();
                const oneTimeUseFlagId = flagDispositionSelection.oneTimeUseFlagId;
                const oneTimeUseFlagDispositionId = parseInt(dispositionSelection);
                await requestUpdateFlagDisposition([{ oneTimeUseFlagId, oneTimeUseFlagDispositionId }]);
                setFlags(currState => {
                    let newState = [...currState];
                    const index = newState.findIndex(ns => ns.oneTimeUseFlagId === oneTimeUseFlagId);
                    newState[index].flagDisposition = { oneTimeUseFlagDispositionId: oneTimeUseFlagDispositionId };
                    newState[index].title = newState[index].title.includes('Image')
                        ? 'Image Flag - Removed'
                        : 'Flag - Removed';
                    return newState;
                });
                setDispositionSelection('');
                setFlagDispositionSelection(null);
            } catch (error) {
                showToast();
            } finally {
                decrementLoading();
            }
        }
    }, [flagDispositionSelection, dispositionSelection, setFlags, showToast, incrementLoading, decrementLoading]);

    return {
        handleFlagDispositionClick,
        dispositions: oneTimeUseFlagDispositionTypes.options,
        flagDispositionSelection,
        flagAndTagListItems,
        dispositionSelection,
        handleDispositionValueChange,
        handleDispositionModalToggle,
        handleSaveFlagDispositionClick,
    };
};

export default useTagsAndFlagsDisplay;

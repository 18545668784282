import { fetchWithAuthHeader } from './AuthUtils';
import { OemId } from 'helpers/OemId';

export const requestRunAllFlagDispositionRules = async (oemId: OemId) => {
    const url = 'api/RepairProcedure/odata/FlagDispositionRules/QueueFlagDispositionMappingRules';
    const params = { oemId: oemId };
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    if (!response.ok) throw new Error(`Failed to run all rules, oemId: ${oemId}`);
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LargeCheckbox } from 'oemiq-common';
import FilterItem from './FilterItem';
import { TaggingWorkFlowStatusEnum } from 'helpers/FlagTagHelper';
import { SEARCH_ACTION_TYPE_ENUM, TABLE_COLUMN_ENUM, TABLE_COLUMNS, ACTIONS } from 'helpers/BulkEditTableHelper';
import { CLEAR_FILTER_AND_SORT } from '../useBulkEdit';

const booleanOptions = Object.freeze([
    { key: 1, value: true, text: 'True' },
    { key: 2, value: false, text: 'False' },
]);

const SearchTool = ({
    quantityConditions,
    partTypes,
    groups,
    onSearch,
    loading,
    onSearchActionChange,
    noGroupChecked,
    noTypeChecked,
    refreshedPendingChecked,
    onResetAllFiltersAndSorts,
}) => {
    const [filters, setFilters] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);

    const filterId = useRef(0);
    const location = useLocation();
    const wasLocationSet = useRef(false);

    useEffect(() => {
        const options = [];
        for (const prop in TaggingWorkFlowStatusEnum) {
            const { Id, Name } = TaggingWorkFlowStatusEnum[prop];
            options.push({
                key: Id,
                value: Id,
                text: Name,
            });
        }
        setStatusOptions(options);
    }, []);

    useEffect(() => {
        const options = [...groups.values()].map(g => ({
            key: g.regionId,
            value: g.regionId,
            text: g.regionFriendlyName,
        }));

        setGroupOptions(options);
    }, [groups]);

    useEffect(() => {
        if (filters.length === 0) {
            if (location.state && !wasLocationSet.current) {
                let action = mapColumnIdToAction(location.state[0].columnId);
                setFilters([
                    {
                        id: ++filterId.current,
                        columnId: location.state[0].columnId,
                        operatorId: location.state[0].operator,
                        inputValue: location.state[0].value,
                        action: action,
                    },
                ]);
                addSerachAction();
                updateSearchAction(filterId.current, location.state[0].operator, location.state[0].value, action);
                wasLocationSet.current = true;
            } else {
                setFilters([{ id: ++filterId.current, columnId: -1, operatorId: -1, inputValue: '', action: null }]);
                addSerachAction();
            }
        }
    }, [filters, location, addSerachAction, updateSearchAction, mapColumnIdToAction, handleUpdate]);

    const handleAddFilterButtonClick = useCallback(() => {
        setFilters([
            ...filters,
            { id: ++filterId.current, columnId: -1, operatorId: -1, inputValue: '', action: null },
        ]);
        addSerachAction();
    }, [filters, addSerachAction]);

    const handleRemoveFilterButtonClick = useCallback(
        id => {
            setFilters(filters.filter(filter => filter.id !== id));
            removeSearchAction(id);
        },
        [filters, removeSearchAction]
    );

    const handleUpdate = useCallback(
        (id, columnId, operatorId, inputValue, isColumnChange) => {
            let action = mapColumnIdToAction(columnId, isColumnChange);
            let updatedFilters = filters.map(filter => {
                if (filter.id === id) {
                    return {
                        ...filter,
                        id: id,
                        columnId: columnId,
                        operatorId: updateOperatorId(isColumnChange, operatorId),
                        inputValue: updateInputValue(isColumnChange, inputValue),
                        action: action,
                    };
                }
                return filter;
            });

            setFilters(updatedFilters);
            updateSearchAction(
                id,
                updateOperatorId(isColumnChange, operatorId),
                updateInputValue(isColumnChange, inputValue),
                action
            );
        },
        [filters, mapColumnIdToAction, updateSearchAction]
    );

    const updateOperatorId = (isColumnChange, operatorId) => {
        if (isColumnChange) {
            return -1;
        }

        return operatorId;
    };

    const updateInputValue = (isColumnChange, inputValue) => {
        if (isColumnChange) {
            return '';
        }

        return inputValue;
    };

    const handleCheckboxToggle = useCallback(
        checkboxName => {
            onSearchActionChange({
                type: SEARCH_ACTION_TYPE_ENUM.TOGGLE_CHECKBOX,
                payload: {
                    checkboxName,
                },
            });
        },
        [onSearchActionChange]
    );

    const handleSearchButtonClick = useCallback(() => {
        onSearch();
    }, [onSearch]);

    const addSerachAction = useCallback(() => {
        onSearchActionChange({
            type: SEARCH_ACTION_TYPE_ENUM.ADD_FILTER,
            payload: {
                id: filterId.current,
                action: null,
            },
        });
    }, [onSearchActionChange]);

    const removeSearchAction = useCallback(
        id => {
            onSearchActionChange({
                type: SEARCH_ACTION_TYPE_ENUM.DELETE_FILTER,
                payload: {
                    id,
                },
            });
        },
        [onSearchActionChange]
    );

    const updateSearchAction = useCallback(
        (id, operatorId, inputValue, action) => {
            onSearchActionChange({
                type: SEARCH_ACTION_TYPE_ENUM.UPDATE_FILTER,
                payload: {
                    id,
                    action: {
                        operatorId,
                        value: inputValue,
                        generateFilterTerm: action.generateFilterTerm,
                    },
                },
            });
        },
        [onSearchActionChange]
    );

    const mapColumnIdToAction = useCallback(
        columnId => {
            if (ACTIONS[columnId]) {
                const action = { ...ACTIONS[columnId] };
                switch (columnId) {
                    case TABLE_COLUMN_ENUM.QUANTITY_CONDITION:
                        action.inputType.props.options = quantityConditions;
                        break;
                    case TABLE_COLUMN_ENUM.PART_TYPE_ID:
                        action.inputType.props.options = partTypes;
                        break;
                    case TABLE_COLUMN_ENUM.GROUPS:
                        action.inputType.props.options = groupOptions;
                        break;
                    case TABLE_COLUMN_ENUM.STATUS:
                        action.inputType.props.options = statusOptions;
                        break;
                    case TABLE_COLUMN_ENUM.NEEDS_ATTENTION:
                        action.inputType.props.options = booleanOptions;
                        break;
                    case TABLE_COLUMN_ENUM.IMAGE:
                        action.inputType.props.options = booleanOptions;
                        break;
                    default:
                        break;
                }

                return action;
            } else {
                return null;
            }
        },
        [groupOptions, statusOptions, quantityConditions, partTypes]
    );

    return (
        <>
            {filters &&
                filters.map(filter => (
                    <FilterItem
                        key={filter.id}
                        id={filter.id}
                        columnId={filter.columnId}
                        operatorId={filter.operatorId}
                        inputValue={filter.inputValue}
                        options={TABLE_COLUMNS.filter(c => c.filterOption).map(c => c.filterOption)}
                        action={filter.action}
                        handleAddFilter={handleAddFilterButtonClick}
                        handleRemoveFilter={handleRemoveFilterButtonClick}
                        handleUpdate={handleUpdate}
                    />
                ))}
            <div className="d-flex mt-3">
                <LargeCheckbox
                    id="no-group-checkbox"
                    label="Filter no group"
                    checked={noGroupChecked}
                    onChange={() => handleCheckboxToggle('NoGroupCheckbox')}
                />
                <LargeCheckbox
                    id="no-type-checkbox"
                    label="Filter no type"
                    className="ms-5"
                    checked={noTypeChecked}
                    onChange={() => handleCheckboxToggle('NoTypeCheckbox')}
                />
                <LargeCheckbox
                    id="refreshed-pending-checkbox"
                    label="Refreshed pending"
                    className="ms-5"
                    checked={refreshedPendingChecked}
                    onChange={() => handleCheckboxToggle('RefreshedPendingCheckbox')}
                />
            </div>
            <div className="d-flex mt-3">
                <button
                    disabled={loading}
                    title="Apply search and filters"
                    id="oem-tagger-search-filter-button"
                    type="button"
                    onClick={handleSearchButtonClick}
                    className="btn btn-primary btn me-3">
                    Apply search/filters
                </button>
                <button
                    disabled={loading}
                    title="Clear All Filters"
                    id="oem-tagger-clear-filter-button"
                    type="button"
                    onClick={() =>
                        onResetAllFiltersAndSorts({
                            resetType: CLEAR_FILTER_AND_SORT.AllFilters,
                            resetSearchToolFilter: () => {
                                setFilters([]);
                            },
                        })
                    }
                    className="btn btn-outline-primary btn me-3">
                    Clear All Filters
                </button>
                <button
                    disabled={loading}
                    title="Clear All Sorts"
                    id="oem-tagger-clear-sort-button"
                    type="button"
                    onClick={() =>
                        onResetAllFiltersAndSorts({
                            resetType: CLEAR_FILTER_AND_SORT.AllSorts,
                            resetSearchToolFilter: () => {
                                setFilters([]);
                            },
                        })
                    }
                    className="btn btn-outline-primary btn me-3">
                    Clear All Sorts
                </button>
                <button
                    disabled={loading}
                    title="Apply search and filters"
                    id="oem-tagger-clear-all-filter-and-sort-button"
                    type="button"
                    onClick={() =>
                        onResetAllFiltersAndSorts({
                            resetType: CLEAR_FILTER_AND_SORT.Both,
                            resetSearchToolFilter: () => {
                                setFilters([]);
                            },
                        })
                    }
                    className="btn btn-outline-primary btn me-3">
                    Clear All Filters and Sorts
                </button>
            </div>
        </>
    );
};

export default SearchTool;

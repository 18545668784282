import { useState, useEffect, useContext, useCallback } from 'react';
import { FormatCurrency } from 'helpers/CurrencyHelper';
import {
    requestCompany,
    requestEditCompany,
    requestCreateOrganizationCompany,
    requestOrganization,
    requestOrganizations,
    requestStagedCompanyTransfer,
    requestOrganizationCompanies,
} from 'api/SecurityApi';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { LoadingContext } from 'components/Layout';
import { ToastContext } from 'components/ToastProvider';
import { addCompanyToNetwork, removeCompanyFromNetwork } from 'api/NetworkApi';
import {
    OEC_STAGING_ORG,
    FORCE_CLOSE_EVENT,
    GetCloseEventOverride,
} from '../../../ManageCustomers/ManageCustomersConstants';
import { useFuzzySearch } from 'hooks/useFuzzySearch';

const LOCATION_NAME_EXISTS_WARNING_ID = `validate-identical-location-link`;

export { LOCATION_NAME_EXISTS_WARNING_ID };

const isTheSameNumericValue = (str, nullable) => {
    // the numeric value of the fields in the flag object can be null
    // and the value of the input field is '' to represent null
    if (str === '' && nullable === null) return true;
    if (str === '' || nullable === null) return false;
    return +str === +nullable;
};

/**
 * Check if the entered values are valid to create/update company's info
 * @param {string} companyName company name
 * @param {string} billingOption selected billing option value
 * @param {string} salesAgreementId sales agreement id
 * @param {string} pricingId pricing id
 * @param {number} companyBillingPrice billing price
 * @returns true if all info is valid, false otherwise
 */
const areValuesValidForCompanyInfo = (
    companyName,
    billingOption,
    salesAgreementId,
    pricingId,
    companyBillingPrice,
    address,
    city,
    zip,
    state,
    countryId
) => {
    return (
        companyName &&
        companyName.length > 0 &&
        // 1: other type check
        (billingOption == '1' ||
            // 2: flat fee type check
            (billingOption == '2' && salesAgreementId !== '' && pricingId !== '') ||
            // 3: transactional type check
            (billingOption == '3' &&
                salesAgreementId !== '' &&
                companyBillingPrice !== null &&
                companyBillingPrice > 0 &&
                pricingId !== '')) &&
        address?.length > 0 &&
        city?.length > 0 &&
        zip?.length > 0 &&
        state?.length > 0 &&
        countryId > -1
    );
};

const useCompanyModal = (orgId, companyId, toggle, onSaveOrEdit, targetOrgId) => {
    // higher order states
    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { showToast } = useContext(ToastContext);
    // local states
    const [company, setCompany] = useState({});
    const [org, setOrg] = useState(null);
    const [orgLocationNames, setOrgLocationNames] = useState([]);
    const { setSearchTerm, searchComplete, searchResults } = useFuzzySearch(orgLocationNames);
    const [nameMatchesExisting, setNameMatchesExisting] = useState(false);
    const [destOrgs, setDestOrgs] = useState([]);
    const [destOrgId, setDestOrgId] = useState(targetOrgId ?? -1);
    const [destOrgSelected, setDestOrgSelected] = useState(false);
    const [networks, setNetworks] = useState([]);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [state, setState] = useState('');
    const [countryId, setCountryId] = useState(-1);
    const [businessPhone, setBusinessPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [inEditState, setInEditState] = useState(false);
    const [oecId, setOecId] = useState('');
    const [billingOption, setBillingOption] = useState(-1);
    const [companyBillingPrice, setCompanyBillingPrice] = useState(''); // not always required
    const [salesAgreementId, setSalesAgreementId] = useState('');
    const [pricingId, setPricingId] = useState('');
    const [isInAppSurveyEnabled, setIsInAppSurveyEnabled] = useState(false);
    const [companyFeatures, setCompanyFeatures] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState(-1);
    const [stagedOrg, setStagedOrg] = useState(false);

    useEffect(() => {
        if (!orgId) return;
        const selectOrg = parseInt(orgId);
        setStagedOrg(selectOrg === OEC_STAGING_ORG.organizationId);
        setDestOrgSelected(destOrgId !== -1);
    }, [orgId, destOrgId, setStagedOrg, setDestOrgSelected]);

    useEffect(() => {
        const prepLocationNames = locations => {
            if (locations && locations.length > 0) {
                return locations.reduce((acc, comp) => {
                    if (parseInt(comp.companyId) === parseInt(companyId)) return acc;
                    // todo: ts
                    acc.push({ key: parseInt(comp.companyId), value: comp.companyName });
                    return acc;
                }, []);
            }
        };

        const getSiblingCompInfo = async () => {
            try {
                incrementLoading();
                const orgOfInterest = parseInt(destOrgId > 0 ? destOrgId : orgId);
                if (!orgOfInterest) return;
                const resp = await requestOrganizationCompanies(orgOfInterest);
                setOrgLocationNames(prepLocationNames(resp));
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        const getDbCompVals = async () => {
            try {
                incrementLoading();
                if (orgId) {
                    const respOrg = await requestOrganization(orgId);
                    setOrg(respOrg);
                }

                const respComp = await requestCompany(companyId);

                setCompany(respComp);
                setCompanyName(respComp.companyName);
                setAddress(respComp.address || '');
                setCity(respComp.city || '');
                setState(respComp.state || '');
                setZip(respComp.zip || '');
                setCountryId(respComp.countryId || -1);
                setBusinessPhone(respComp.businessPhone || '');
                setWebsite(respComp.website || '');
                setOecId(respComp.oecId || '');
                setBillingOption(parseInt(respComp.billingType) || -1);
                if (respComp.price) setCompanyBillingPrice(FormatCurrency(respComp.price, 2));
                setSalesAgreementId(respComp.salesAgreementId || '');
                setPricingId(respComp.pricingId || '');
                setIsInAppSurveyEnabled(respComp.isInAppSurveyEnabled || false);
                setCompanyFeatures(respComp.features || []);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        const prepareDisplayOrgs = orgs => {
            if (orgs && orgs.length > 0) {
                orgs = orgs.reduce((acc, org) => {
                    if (!org.organizationId || org.organizationId === OEC_STAGING_ORG.organizationId) return acc;
                    acc.push({ value: org.organizationId, text: org.name });
                    return acc;
                }, []);

                setDestOrgs(orgs);
            }
        };

        const getOrgs = async () => {
            try {
                incrementLoading();
                const resOrgs = await requestOrganizations();
                prepareDisplayOrgs(resOrgs);
            } catch (error) {
                showToast(error);
            } finally {
                decrementLoading();
            }
        };

        getSiblingCompInfo();
        if (stagedOrg) getOrgs();
        if (companyId) getDbCompVals();
    }, [
        companyId,
        orgId,
        destOrgId,
        showToast,
        incrementLoading,
        decrementLoading,
        stagedOrg,
        setDestOrgs,
        setOrgLocationNames,
    ]);

    const getCompExistsMessage = useCallback(() => {
        if (!searchComplete || !searchResults || searchResults.length === 0) return '';

        let message = org
            ? `Company "${companyName}" already exists inside of ${org.name}!`
            : `Company "${companyName}" already exists in this organization!`;

        return message;
    }, [searchComplete, searchResults, companyName, org]);

    useEffect(() => {
        setNameMatchesExisting(
            searchComplete && !!searchResults && searchResults.length >= 1 && searchResults[0].isExactMatch
        );
    }, [searchComplete, searchResults, setNameMatchesExisting]);

    useEffect(() => {
        const required = areValuesValidForCompanyInfo(
            companyName,
            billingOption,
            salesAgreementId,
            pricingId,
            companyBillingPrice,
            address,
            city,
            zip,
            state,
            countryId
        );

        const unchanged = companyId
            ? company.companyName === companyName /* Required */ &&
              (company.address === address || (!company.address && !address)) &&
              (company.city === city || (!company.city && !address)) &&
              (company.zip === zip || (!company.zip && !zip)) &&
              (company.state === state || (!company.state && !state)) &&
              (company.countryId === countryId || (!company.countryId && countryId === -1)) &&
              (company.businessPhone === businessPhone || (!company.businessPhone && !businessPhone)) &&
              (company.website === website || (!company.website && !website)) &&
              (company.billingType === parseInt(billingOption) || (!company.billingType && billingOption === -1)) &&
              (isTheSameNumericValue(company.price, companyBillingPrice) || (!company.price && !companyBillingPrice)) &&
              (company.pricingId === pricingId || (!company.pricingId && !pricingId)) &&
              (company.salesAgreementId === salesAgreementId || (!company.salesAgreementId && !salesAgreementId)) &&
              (company.oecId === oecId || (company.oecId === null && !oecId)) &&
              company.isInAppSurveyEnabled === isInAppSurveyEnabled &&
              company.features.length === companyFeatures.length &&
              company.features.filter(cf => !companyFeatures.find(f => cf.id === f.id)).length === 0
            : !companyName &&
              !address &&
              !city &&
              !zip &&
              !state &&
              countryId === -1 &&
              !businessPhone &&
              !website &&
              billingOption === -1 &&
              !oecId &&
              !salesAgreementId &&
              !pricingId &&
              !isInAppSurveyEnabled;

        const unique =
            !unchanged &&
            searchComplete &&
            (!searchResults || searchResults.filter(sr => sr.isExactMatch).length === 0);

        setInEditState(!unchanged);
        setSaveEnabled(!unchanged && required && unique);
    }, [
        companyName,
        address,
        city,
        zip,
        state,
        businessPhone,
        website,
        companyId,
        company,
        oecId,
        salesAgreementId,
        billingOption,
        companyBillingPrice,
        pricingId,
        isInAppSurveyEnabled,
        companyFeatures,
        countryId,
        searchResults,
        searchComplete,
    ]);

    useEffect(() => {
        if (!company || !org) return;
        let orgNetworks = [...org.networks];
        orgNetworks?.map(o => {
            o.name = `${o.name} (from organization)`;
            o.showTrash = false;
        });

        setNetworks([...orgNetworks, ...(company.networks ?? [])]);
    }, [company, org]);

    const handleSave = async () => {
        if (!saveEnabled) return;
        const price = parseInt(billingOption) === 3 ? companyBillingPrice : null;
        try {
            incrementLoading();
            if (companyId) {
                await requestEditCompany(
                    companyId,
                    companyName,
                    address,
                    city,
                    state,
                    zip,
                    countryId,
                    businessPhone,
                    website,
                    parseInt(billingOption),
                    price,
                    oecId,
                    salesAgreementId,
                    pricingId,
                    isInAppSurveyEnabled,
                    companyFeatures
                );
            } else {
                await requestCreateOrganizationCompany(
                    orgId,
                    companyName,
                    address,
                    city,
                    state,
                    zip,
                    countryId,
                    businessPhone,
                    website,
                    parseInt(billingOption),
                    price,
                    oecId,
                    salesAgreementId,
                    pricingId,
                    isInAppSurveyEnabled,
                    companyFeatures
                );
            }
            // update the current company object
            setCompany(prev => ({
                ...prev,
                companyName,
                address,
                city,
                state,
                zip,
                countryId,
                businessPhone,
                website,
                billingType: billingOption,
                price,
                oecId,
                salesAgreementId,
                pricingId,
                isInAppSurveyEnabled,
                features: companyFeatures,
            }));
            setInEditState(false);
            await onSaveOrEdit();
            notifications.pushSuccess(`${companyName} updated successfully`);
            handleToggle(FORCE_CLOSE_EVENT);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    };

    const handleOrgTransfer = useCallback(async () => {
        try {
            incrementLoading();
            // if edits to company were made
            const companyUpdate =
                inEditState && saveEnabled && destOrgId && parseInt(destOrgId) > 0
                    ? {
                          companyName: companyName,
                          address: address,
                          city: city,
                          state: state,
                          zip: zip,
                          countryId: countryId,
                          businessPhone: businessPhone,
                          website: website,
                          billingType: parseInt(billingOption),
                          price: parseInt(billingOption) === 3 ? companyBillingPrice : null,
                          oecId: oecId,
                          salesAgreementId: salesAgreementId,
                          pricingId: pricingId,
                          isInAppSurveyEnabled: isInAppSurveyEnabled,
                          companyFeatures: companyFeatures,
                      }
                    : null;
            await requestStagedCompanyTransfer(companyId, destOrgId, companyUpdate);
            await onSaveOrEdit();
            if (location && location.state && location.state.stagedComps) {
                const newStagedComps = location.state.stagedComps.filter(
                    c => parseInt(c.companyId) !== parseInt(companyId)
                );
                location.state = {
                    ...location.state,
                    stagedComps: newStagedComps ?? null,
                };
            }
            notifications.pushSuccess(
                `${companyName} transferred to ${
                    destOrgs.find(o => parseInt(o.value) === parseInt(destOrgId)).text ?? `Organization ${destOrgId}`
                } successfully`
            );
            handleToggle(FORCE_CLOSE_EVENT);
        } catch (error) {
            showToast(error);
        } finally {
            decrementLoading();
        }
    }, [
        companyId,
        destOrgId,
        destOrgs,
        companyName,
        incrementLoading,
        notifications,
        showToast,
        decrementLoading,
        onSaveOrEdit,
        handleToggle,
        inEditState,
        saveEnabled,
        address,
        city,
        state,
        zip,
        countryId,
        businessPhone,
        website,
        billingOption,
        companyBillingPrice,
        oecId,
        salesAgreementId,
        pricingId,
        isInAppSurveyEnabled,
        companyFeatures,
    ]);

    const handleToggle = useCallback(
        e => {
            let force = GetCloseEventOverride(e);
            if ((!inEditState && !destOrgSelected) || force) {
                setCompany({});
                setCompanyName('');
                setAddress('');
                setCity('');
                setState('');
                setZip('');
                setCountryId(-1);
                setBusinessPhone('');
                setWebsite('');
                setBillingOption(-1);
                setOecId('');
                setSalesAgreementId('');
                setPricingId('');
                setSaveEnabled(false);
                setInEditState(false);
                toggle();
            }
        },
        [
            inEditState,
            destOrgSelected,
            toggle,
            setCompany,
            setCompanyName,
            setAddress,
            setCity,
            setState,
            setZip,
            setCountryId,
            setBusinessPhone,
            setWebsite,
            setBillingOption,
            setOecId,
            setSalesAgreementId,
            setPricingId,
            setSaveEnabled,
            setInEditState,
        ]
    );

    const handleFeatureToggle = async feature => {
        setCompanyFeatures(prev => {
            const features = prev.filter(cf => cf.id !== feature.id);
            if (features.length === prev.length) {
                return [...features, feature];
            }
            return features;
        });
    };

    const onDeleteClick = async networkToRemove => {
        await removeCompanyFromNetwork(networkToRemove.networkId, companyId);
        setCompany(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            newState.networks = newState.networks.filter(n => n.networkId !== networkToRemove.networkId);
            return newState;
        });
    };

    const onAddNetwork = async () => {
        let network = await addCompanyToNetwork(selectedNetwork, companyId);
        setCompany(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            newState.networks = [...newState.networks, network];
            return newState;
        });
        setSelectedNetwork(-1);
    };

    return {
        networks,
        setNetworks,
        saveEnabled,
        setSaveEnabled,
        companyName,
        setCompanyName,
        address,
        setAddress,
        city,
        setCity,
        zip,
        setZip,
        state,
        setState,
        countryId,
        setCountryId,
        businessPhone,
        setBusinessPhone,
        website,
        setWebsite,
        inEditState,
        setInEditState,
        oecId,
        setOecId,
        billingOption,
        setBillingOption,
        companyBillingPrice,
        setCompanyBillingPrice,
        salesAgreementId,
        setSalesAgreementId,
        pricingId,
        setPricingId,
        isInAppSurveyEnabled,
        setIsInAppSurveyEnabled,
        companyFeatures,
        selectedNetwork,
        setSelectedNetwork,
        handleSave,
        handleToggle,
        handleFeatureToggle,
        onDeleteClick,
        onAddNetwork,
        destOrgs,
        destOrgId,
        setDestOrgId,
        handleOrgTransfer,
        stagedOrg,
        destOrgSelected,
        setSearchTerm,
        searchResults,
        nameMatchesExisting,
        getCompExistsMessage,
    };
};

export default useCompanyModal;

export const roles = {
    admin: 'OemIq.Corporate.Admin',
    siteAdmin: 'OemIq.Corporate.SiteAdmin',
    dataSME: 'OemIq.Corporate.DataSME',
    dataSpecialist: 'OemIq.Corporate.DataSpecialist',
    customerSupport: 'OemIq.Corporate.CustomerSupport',
    customerSuccess: 'OemIq.Corporate.CustomerSuccess',
    threeDModeler: 'OemIq.Corporate.3DModeler',
};

const privilegesMap = {
    [roles.admin]: {
        tool: [
            'mappingProcess',
            'mappingProcess.mapper',
            'mappingProcess.rulesCreator',
            'mappingProcess.rulesRunner',
            'mappingProcess.procedures',
            'mappingProcess.positionStatements',
            'mappingProcess.mappingTraining',
            'taggingProcess',
            'taggingProcess.tagger',
            'taggingProcess.flagger',
            'taggingProcess.flagDispositionRules',
            'vehicleProcess',
            'vehicleProcess.publisher',
            'vehicleProcess.vehicleManagement',
            'vehicleProcess.treeDModel',
            'refreshManager',
            'refreshManager.refreshPublisher',
            'refreshManager.importManager',
            'refreshManager.ingestionManager',
            'manageCustomers',
            'manageCustomers.organizations',
            'manageCustomers.networks',
            'other',
            'other.styleGuide',
            'other.reporting',
            'other.graphExplorer',
        ],
        rule: ['toggleActive', 'run', 'undo', 'create', 'remove'],
        flagTerm: ['create', 'run', 'undo', 'delete', 'toggleActive'],
        importManager: ['runRefresh', 'runBook', 'pauseRefreshSchedule', 'editRefreshSchedule'],
        prototypes: ['togglePrototype'],
    },
    [roles.siteAdmin]: {
        tool: [
            'mappingProcess',
            'mappingProcess.mapper',
            // under construction: 'mappingProcess.rulesCreator',
            'mappingProcess.rulesRunner',
            'mappingProcess.procedures',
            'mappingProcess.positionStatements',
            'mappingProcess.mappingTraining',
            'taggingProcess',
            'taggingProcess.tagger',
            'taggingProcess.flagger',
            'taggingProcess.flagDispositionRules',
            'vehicleProcess',
            'vehicleProcess.publisher',
            'vehicleProcess.vehicleManagement',
            'vehicleProcess.treeDModel',
            // disable: 'refreshManager',
            // disable: 'refreshManager.refreshPublisher',
            'manageCustomers',
            'manageCustomers.organizations',
            'manageCustomers.networks',
            'other',
            'other.styleGuide',
            'other.reporting',
            'other.graphExplorer',
        ],
    },
    [roles.dataSME]: {
        tool: [
            'mappingProcess',
            'mappingProcess.mapper',
            'mappingProcess.rulesCreator',
            'mappingProcess.rulesRunner',
            'mappingProcess.procedures',
            'mappingProcess.positionStatements',
            'mappingProcess.mappingTraining',
            'taggingProcess',
            'taggingProcess.tagger',
            'taggingProcess.flagger',
            'taggingProcess.flagDispositionRules',
            'vehicleProcess',
            'vehicleProcess.publisher',
            'vehicleProcess.vehicleManagement',
            'vehicleProcess.treeDModel',
            'refreshManager',
            'refreshManager.refreshPublisher',
            'refreshManager.importManager',
            'refreshManager.ingestionManager',
            'manageCustomers',
            'manageCustomers.organizations',
            'manageCustomers.networks',
            'other',
            'other.styleGuide',
            'other.reporting',
            'other.graphExplorer',
        ],
        rule: ['toggleActive', 'run', 'undo', 'create', 'remove'],
        flagTerm: ['create', 'run', 'undo', 'delete', 'toggleActive'],
    },
    [roles.dataSpecialist]: {
        tool: [
            'mappingProcess',
            'mappingProcess.mapper',
            // under construction: 'mappingProcess.rulesCreator',
            'mappingProcess.rulesRunner',
            'mappingProcess.procedures',
            'mappingProcess.positionStatements',
            'mappingProcess.mappingTraining',
            'taggingProcess',
            'taggingProcess.tagger',
            'taggingProcess.flagger',
            'taggingProcess.flagDispositionRules',
            'vehicleProcess',
            'vehicleProcess.publisher',
            // disable: 'vehicleProcess.vehicleManagement',
            'vehicleProcess.treeDModel',
            // disable: 'refreshManager',
            // disable: 'refreshManager.refreshPublisher',
            'manageCustomers',
            'manageCustomers.organizations',
            'manageCustomers.networks',
            'other',
            'other.styleGuide',
            'other.reporting',
            'other.graphExplorer',
        ],
    },
    [roles.customerSupport]: {
        tool: ['manageCustomers', 'manageCustomers.organizations', 'manageCustomers.networks'],
    },
    [roles.customerSuccess]: {
        tool: ['manageCustomers', 'manageCustomers.organizations', 'manageCustomers.networks'],
    },
    [roles.threeDModeler]: {
        tool: ['vehicleProcess', 'vehicleProcess.treeDModel'],
    },
};

export default privilegesMap;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import { requestFlagDispositionRules } from 'api/RepairProcedureApi';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import 'components/Shared/Table/Table.scss';
import { OemId } from 'helpers/OemId';
import { PrimaryButton } from 'oemiq-common';
import useModal from 'hooks/useModal';
import ModalOperations, { OperationSite, OperationTypes } from 'components/Shared/ModalOperations/ModalOperations';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { requestRunAllFlagDispositionRules } from 'api/FlagDispositionApi';
import { requestAreAllOperationsFinishedSinceTime } from 'api/RepairProcedures/RepairProcedureOperationApi';

const mapFlagDispositionRuleField = (key: string): string => {
    return match(key)
        .with('ProcedureTitle', () => 'Procedure Title')
        .otherwise(() => key);
};

const mapFlagDispositionRuleOperator = (key: string): string => {
    return match(key)
        .with('Equals', () => 'equals')
        .with('Contains', () => 'contains')
        .with('NotContains', () => 'not contains')
        .otherwise(() => key);
};

const mapFlagDispositionRuleValue = (groups, types, key: string, ruleDefinitionValue: string): string => {
    const getValueByName = (
        items: unknown[],
        key: string,
        valueKey: string,
        ruleDefinitionValue: string
    ): string | undefined => {
        const value = parseInt(ruleDefinitionValue, 10);
        const matchingItem = items.find(item => item[key] === value);
        return matchingItem ? matchingItem[valueKey] : undefined;
    };

    return match(key)
        .with('Group', () => getValueByName(groups, 'regionId', 'regionFriendlyName', ruleDefinitionValue))
        .with('Type', () => getValueByName(types, 'oemIqSectionId', 'oemIqSectionName', ruleDefinitionValue))
        .otherwise(() => ruleDefinitionValue);
};

const operationCyclicCheckTime = 10000;

const FlagDispositionRules = () => {
    const { groups, types } = useContext(MappingDefinitionsContext);
    const { notifications } = useContext(NotificationsContext);
    const { oemId } = useParams();
    const parsedOemId = parseInt(oemId) as OemId;

    const [flagDispositionRules, setFlagDispositionRules] = useState([]);
    const [isRunAllRulesDisabled, setIsRunAllRulesDisabled] = useState(false);

    useEffect(() => {
        const fetchFlagDispositionRules = async () => {
            const response = await requestFlagDispositionRules(parsedOemId);
            response.sort((a, b) => a.order - b.order);
            setFlagDispositionRules(response);
        };

        fetchFlagDispositionRules();
    }, [parsedOemId]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const getAreAllOperationsFinished = async () => {
            const finished = await requestAreAllOperationsFinishedSinceTime(parsedOemId, operationCyclicCheckTime, [
                OperationTypes.ApplyFlagDispositionRules,
            ]);
            setIsRunAllRulesDisabled(!finished);
            timeout = setTimeout(getAreAllOperationsFinished, operationCyclicCheckTime);
        };

        getAreAllOperationsFinished();
        return () => timeout && clearTimeout(timeout);
    }, [parsedOemId]);

    const runAllRules = useCallback(async () => {
        try {
            setIsRunAllRulesDisabled(true);
            await requestRunAllFlagDispositionRules(parsedOemId);
            notifications.pushSuccess('All rules will run for entire oem');
        } catch (error) {
            notifications.pushExceptionDanger(error);
        }
    }, [notifications, parsedOemId]);

    const {
        isModalOpen: isOperationsModalOpen,
        openModal: openOperationsModal,
        closeModal: closeOperationsModal,
    } = useModal();

    return (
        <div className="pt-3 pe-3 ps-3 d-flex flex-column">
            <div className="d-flex mb-3">
                <h2 className="flex-grow-1">Flag Disposition Rules</h2>
                <PrimaryButton
                    id="open-run-all-modal"
                    className="me-3"
                    type="button"
                    onClick={runAllRules}
                    disabled={isRunAllRulesDisabled}>
                    Run All Rules
                </PrimaryButton>
                <PrimaryButton id="open-operations-modal" className="me-3" type="button" onClick={openOperationsModal}>
                    Operations
                </PrimaryButton>
            </div>
            <table className="table sticky-header">
                <thead>
                    <tr>
                        <th id="ruleId">Id</th>
                        <th id="order">Order</th>
                        <th id="ruleConditions">Rule Conditions</th>
                        <th id="ruleDisposition">Disposition</th>
                        <th id="ruleIsActive">Is Active</th>
                    </tr>
                </thead>
                <tbody>
                    {flagDispositionRules.map(rule => (
                        <tr key={rule.ruleId}>
                            <td headers="ruleId">{rule.ruleId}</td>
                            <td headers="order">{rule.order}</td>
                            <td headers="ruleConditions">
                                {rule.flagDispositionRuleDefinitions.map(ruleDefinition => {
                                    return (
                                        <span
                                            key={ruleDefinition.ruleDefinitionId}
                                            className={`badge me-2 mt-2 pe-2 rule-label`}>
                                            <span>{mapFlagDispositionRuleField(ruleDefinition.field)}</span>
                                            <span className="badge rule-condition-pill">
                                                {mapFlagDispositionRuleOperator(ruleDefinition.operator)}
                                            </span>
                                            <span className="badge rule-pill">
                                                {mapFlagDispositionRuleValue(
                                                    groups,
                                                    types,
                                                    ruleDefinition.field,
                                                    ruleDefinition.value
                                                )}
                                            </span>
                                        </span>
                                    );
                                })}
                            </td>
                            <td headers="ruleDisposition">
                                {rule.oneTimeUseFlagDisposition.oneTimeUseFlagDispoistionName}
                            </td>
                            <td headers="ruleIsActive">{rule.isActive ? 'Yes' : 'No'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {isOperationsModalOpen && (
                <ModalOperations
                    oemId={parsedOemId}
                    operationTypes={[OperationTypes.ApplyFlagDispositionRules]}
                    modalType={OperationSite.FlagDispositionRules}
                    isOperationsModalOpen={isOperationsModalOpen}
                    closeOperationsModalCallback={closeOperationsModal}
                />
            )}
        </div>
    );
};

export default FlagDispositionRules;

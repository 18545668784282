import { buildProcedureProperty } from './ProcedureProperty';
import { COMMON_FILTERS, COMMON_PROPERTIES, LAST_COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const OEM_METADATA_PROPERTY = 'latestGMProcedure';

const RULABLE_PROPERTIES = [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.deliverySectionTitle',
        displayName: 'Delivery Section Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'deliverySectionTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.deliverySubSectionTitle',
        displayName: 'Delivery SubSection Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'deliverySubSectionTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.deliverySITTitle',
        displayName: 'Delivery SIT Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'deliverySITTitle',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.title',
        displayName: 'Title',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'title',
    }),
];

export const GM_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 11,
    oemName: 'GM',
    properties: [...COMMON_PROPERTIES, ...RULABLE_PROPERTIES, ...LAST_COMMON_PROPERTIES],
    ruleKey: 'gmMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type GMMappingRule = OemMappingRuleBase & {
    gmMappingRuleId: number;
};

class _GMMappingEngineService implements OemEngineService<GMMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = GM_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: GMMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: GMMappingRule): Promise<GMMappingRule> => {
        const newRule = { ...rule };
        const gmMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.gmMappingRuleId = gmMappingRuleId;

        return newRule;
    };
}

export const GMMappingEngineService = new _GMMappingEngineService();

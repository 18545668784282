import React, { FC } from 'react';
import './CheckboxDropdown.scss';
import { ICheckboxDropdownProps } from '../types';
import useCheckboxDropdown from './useCheckboxDropdown';
import useClickOutside from 'hooks/useClickOutside';

const CheckBoxDropdown: FC<ICheckboxDropdownProps> = ({
    id,
    options,
    allOptionEnabled,
    optionsSearchEnabled,
    dropdownBtn,
    activeDropdownBtn,
    handleSelectionChange,
    defaultSelected,
}) => {
    const {
        allOptionState,
        handleCheckboxChange,
        handleAllOptionClick,
        selectedOptions,
        handleSearchTermChange,
        visibleOptions,
        handleMouseOverTrigger,
        handleMouseLeavingTrigger,
        isActive,
    } = useCheckboxDropdown(handleSelectionChange, options, defaultSelected);

    const { compRef, isComponentVisible, setIsComponentVisible } = useClickOutside(false);

    return (
        <div id={id} className="cb-dropdown" ref={compRef}>
            <div
                className="cb-dropdown-trigger"
                data-testid="cb-dropdown-trigger"
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible);
                }}
                onMouseEnter={handleMouseOverTrigger}
                onMouseLeave={handleMouseLeavingTrigger}>
                {isComponentVisible || isActive ? activeDropdownBtn : dropdownBtn}
            </div>
            {isComponentVisible && (
                <div className="cb-dropdown-menu" data-testid="cb-dropdown-menu" onBlur={close} tabIndex={0}>
                    {allOptionEnabled && (
                        <label className="cb-dropdown-option all-option">
                            <input type="checkbox" checked={allOptionState} onChange={() => handleAllOptionClick()} />
                            All
                        </label>
                    )}
                    {optionsSearchEnabled && (
                        <input
                            type="text"
                            onChange={e => handleSearchTermChange(e.target.value)}
                            className="cb-dropdown-options-search"
                            placeholder="Search"
                        />
                    )}
                    {visibleOptions.map((option, index) => (
                        <label key={index} className="cb-dropdown-option">
                            <input
                                type="checkbox"
                                checked={selectedOptions.includes(option.value)}
                                onChange={() => handleCheckboxChange(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CheckBoxDropdown;

import React, { forwardRef, useState, useImperativeHandle, useMemo, useCallback } from 'react';
import { ReactModal, PrimaryButton, SuccessButton } from 'oemiq-common';
import ReactSelect from 'react-select';
import useModal from 'hooks/useModal';
import { getNewFilterItem } from './tableFilterHelpers';
import { isFilterRulable } from './operators';

const ruleableStyles = (ruleable, topRight = 0) =>
    ruleable && {
        position: 'relative',
        '::after': {
            content: '"RULEABLE"',
            position: 'absolute',
            top: topRight,
            right: topRight,
            padding: '0 5px',
            borderRadius: '3px',
            color: '#fff',
            backgroundColor: '#28a745',
            fontWeight: '300',
        },
    };

const TableFiltersAddEdit = forwardRef(({ filterConfigurations, disableFilters, onAddEdit }, ref) => {
    const { isModalOpen, openModal, closeModal } = useModal();
    const [filter, setFilter] = useState(getNewFilterItem());
    const filterConfiguration = useMemo(() => {
        return filterConfigurations.map[filter.id];
    }, [filterConfigurations, filter.id]);

    const open = (displayFilter = getNewFilterItem()) => {
        setFilter(displayFilter);
        openModal();
    };

    const close = () => {
        setFilter(getNewFilterItem());
        closeModal();
    };

    const filterApply = () => {
        onAddEdit(filter);
        closeModal();
    };

    const setFilterValue = useCallback(v => {
        setFilter(current => ({
            ...current,
            value: v.value,
            valueList: v.valueList,
        }));
    }, []);

    useImperativeHandle(ref, () => ({
        close,
        open,
    }));

    return (
        <ReactModal
            id="modal-operations"
            isOpen={isModalOpen}
            toggle={close}
            headerComponent={<>Add filter</>}
            bodyComponent={
                <>
                    <ReactSelect
                        className="mb-2"
                        placeholder="Choose filter"
                        options={filterConfigurations.list ? filterConfigurations.list : []}
                        getOptionValue={o => o.id}
                        value={filterConfigurations.list.find(f => filter.id === f.id)}
                        onChange={e =>
                            setFilter(() =>
                                getNewFilterItem({
                                    id: e.id,
                                    key: filter.key,
                                    value: e.type?.defaultValue ?? '',
                                    operator: e.type?.operators.length === 1 ? e.type.operators[0] : '',
                                })
                            )
                        }
                        isOptionDisabled={o => disableFilters.some(fid => fid === o.id)}
                        styles={{
                            singleValue: (provided, { data }) => ({ ...provided, ...ruleableStyles(data.ruleable) }),
                            option: (provided, { data }) => ({ ...provided, ...ruleableStyles(data.ruleable, '7px') }),
                        }}
                    />
                    <ReactSelect
                        className="mb-2"
                        placeholder="Choose operator"
                        options={filter.id ? filterConfiguration.type.operators : []}
                        isDisabled={!filter.id}
                        value={filter.operator ? filter.operator : null}
                        onChange={e => setFilter(current => ({ ...current, operator: e }))}
                        styles={{
                            singleValue: (provided, { data }) => ({
                                ...provided,
                                ...ruleableStyles(isFilterRulable(filterConfiguration, data)),
                            }),
                            option: (provided, { data }) => ({
                                ...provided,
                                ...ruleableStyles(isFilterRulable(filterConfiguration, data), '7px'),
                            }),
                        }}
                    />
                    {filterConfiguration && (
                        <filterConfiguration.type.component filter={filter} setFilterValue={setFilterValue} />
                    )}
                </>
            }
            footerComponent={
                <>
                    <PrimaryButton id="modal-edit-filters-cancel" type="button" onClick={close}>
                        Cancel
                    </PrimaryButton>
                    <SuccessButton
                        id="modal-edit-filters-apply"
                        className="me-3"
                        type="button"
                        disabled={
                            !filter.id ||
                            !filter.operator ||
                            (!filter.value && !filterConfiguration.type.allowFalse) ||
                            (filterConfiguration.type.validator && !filterConfiguration.type.validator(filter.value))
                        }
                        onClick={filterApply}>
                        Apply
                    </SuccessButton>
                </>
            }
            className={'modal-lg sticky-footer'}
        />
    );
});

export default TableFiltersAddEdit;

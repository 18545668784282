import { buildProcedureProperty } from './ProcedureProperty';
import { COMMON_FILTERS, COMMON_PROPERTIES, LAST_COMMON_PROPERTIES } from './BaseProcedureMetadata';
import { requestCreateNewMappingRule } from 'api/RepairProcedures/RepairProcedureMappingRuleApi';
import { buildOemMetadata, OemMetadata } from './BuildOemMetadata';
import { isEmpty, isNil } from 'lodash';
import { PROPERTY_TYPE } from './PropertyType';
import { OemEngineService, OemMappingRuleBase } from './types';

const OEM_METADATA_PROPERTY = 'latestHyundaiProcedure';

const RULEABLE_PROPERTIES = [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel1',
        displayName: 'MetaLevel 1',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metalevel1',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel2',
        displayName: 'MetaLevel 2',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metalevel2',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel3',
        displayName: 'MetaLevel 3',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metalevel3',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel4',
        displayName: 'MetaLevel 4',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metalevel4',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel5',
        displayName: 'MetaLevel 5',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metalevel5',
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.metaLevel6',
        displayName: 'MetaLevel 6',
        type: PROPERTY_TYPE.string,
        rulePropertyName: 'metalevel6',
    }),
];

const NON_RULEABLE_PROPERTIES = [
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.oemProcedureId',
        displayName: 'OemProcedureId',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.version',
        displayName: 'Version',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.htmlFileName',
        displayName: 'Html File Name',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureTitle',
        displayName: 'Procedure Title',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.group',
        displayName: 'Group',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.family',
        displayName: 'Family',
        type: PROPERTY_TYPE.string,
    }),
    //buildProcedureProperty({
    //    name: OEM_METADATA_PROPERTY + '.siteInfoList',
    //    displayName: 'Site Info List',
    //    type: PROPERTY_TYPE.string,
    //}),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelYear',
        displayName: 'Model Year',
        type: PROPERTY_TYPE.number,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelSerialNumber',
        displayName: 'Model Serial Number',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelCode',
        displayName: 'Model Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelName',
        displayName: 'Model Name',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.cat1',
        displayName: 'Cat1',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.contentNo',
        displayName: 'Content No',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.contentSsn',
        displayName: 'Content Ssn',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.FfirstNodeDescription',
        displayName: 'First Node Description',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.nodeDescription',
        displayName: 'Node Description',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureSections',
        displayName: 'Procedure Sections',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.procedureTitleSections',
        displayName: 'Procedure Title Sections',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.serviceCode',
        displayName: 'Service Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.qualifiedServiceCode',
        displayName: 'Qualified Service Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.componentId',
        displayName: 'Component Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col3',
        displayName: 'Col3',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.query',
        displayName: 'Query',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.qualifier',
        displayName: 'Qualifier',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.languageCode',
        displayName: 'Language Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.manufacturerCode',
        displayName: 'Manufacturer Code',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.modelId',
        displayName: 'Model Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSystem',
        displayName: 'Sub System',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.subSystemId',
        displayName: 'Sub System Id',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.pcode',
        displayName: 'Pcode',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col13',
        displayName: 'Col13',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col14',
        displayName: 'Col14',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.col17',
        displayName: 'Col17',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.dataSource',
        displayName: 'Data Source',
        type: PROPERTY_TYPE.string,
    }),
    buildProcedureProperty({
        name: OEM_METADATA_PROPERTY + '.revisionNumber',
        displayName: 'Revision Number',
        type: PROPERTY_TYPE.string,
    }),
];

export const HYUNDAI_PROCEDURE_METADATA = buildOemMetadata({
    oemId: 19,
    oemName: 'Hyundai',
    properties: [...COMMON_PROPERTIES, ...RULEABLE_PROPERTIES, ...NON_RULEABLE_PROPERTIES, ...LAST_COMMON_PROPERTIES],
    ruleKey: 'hyundaiMappingRuleId',
    filters: [...COMMON_FILTERS],
});

type HyundaiMappingRule = OemMappingRuleBase & {
    hyundaiMappingRuleId: number;
};

class _HyundaiMappingEngineService implements OemEngineService<HyundaiMappingRule> {
    metadata: OemMetadata;

    constructor() {
        this.metadata = HYUNDAI_PROCEDURE_METADATA;
    }

    isRuleValid = (rule: HyundaiMappingRule): boolean => {
        return (
            (!isNil(rule.typeId) || !isEmpty(rule.groupIds)) &&
            this.metadata.ruleable.some(p => rule[p.rulePropertyName])
        );
    };

    createRule = async (rule: HyundaiMappingRule): Promise<HyundaiMappingRule> => {
        const newRule = { ...rule };
        const hyundaiMappingRuleId = await requestCreateNewMappingRule(this.metadata.oemId, newRule);
        newRule.hyundaiMappingRuleId = hyundaiMappingRuleId;

        return newRule;
    };
}

export const HyundaiMappingEngineService = new _HyundaiMappingEngineService();

import { fetchWithAuthHeader } from 'api/AuthUtils';
import {
    getMappingRulesUrl,
    getCreateMappingRuleUrl,
    setActiveMappingRuleUrl,
    removeMappingRuleUrl,
    getMappingRuleIdKey,
} from './RepairProceduresUrls';
import serializeParams from 'helpers/ParamsSerializeHelper';

const PAGE_SIZE = 100;

export const formatParamsForCreateRule = (filters, groups, type) => {
    let params = {
        groupIds: groups.map(g => g.value),
        typeId: type?.value || null,
    };
    params = filters.reduce(
        (a, c) => ({
            ...a,
            [c.rulePropertyName]: {
                operator: c.operator,
                value: c.value,
            },
        }),
        { ...params }
    );
    return params;
};

export const requestMappingRules = async (oemId, page, filters) => {
    const tFilters = [...filters, { urlFilter: 'isDeleted%20eq%20false' }];
    const params = {
        $top: PAGE_SIZE,
        $skip: (page - 1) * PAGE_SIZE,
        $count: true,
        $filter: tFilters.map(f => f.urlFilter).join(' and '),
        $orderby: `${getMappingRuleIdKey(oemId)} desc`,
        $expand: 'statistics',
    };
    const url = getMappingRulesUrl(oemId);
    const response = await fetchWithAuthHeader(`${url}?${serializeParams(params)}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error(`Failed to get OEM mapping rules, oemId: ${oemId}`);

    const res = await response.json();
    const totalCount = res['@odata.count'];
    const all = totalCount - params.$skip <= PAGE_SIZE;

    return {
        newRules: res.value,
        totalCount,
        all,
    };
};

export const requestMappingRuleStatistics = async (oemId, ruleId) => {
    const params = {
        $filter: `${getMappingRuleIdKey(oemId)} eq ${ruleId}`,
        $select: 'statistics',
        $expand: 'statistics',
        $count: false,
    };
    const url = getMappingRulesUrl(oemId);
    const response = await fetchWithAuthHeader(`${url}?${serializeParams(params)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (!response.ok) throw new Error(`Failed to get OEM mapping rules, oemId: ${oemId}`);

    const res = await response.json();
    return res.value[0].statistics;
};

export const requestCreateNewMappingRule = async (oemId, rule) => {
    const url = getCreateMappingRuleUrl(oemId);
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(rule),
    });
    if (!response.ok) throw new Error(`Failed to create OEM mapping rule, oemId: ${oemId}`);

    return (await response.json()).value;
};

export const requestSetActiveMappingRule = async (oemId, key, isActive) => {
    const url = setActiveMappingRuleUrl(oemId)({ key });
    const response = await fetchWithAuthHeader(url, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isActive }),
    });
    if (!response.ok) throw new Error(`Failed to set active OEM mapping rule, oemId: ${oemId}`);

    return (await response.json()).value;
};

export const requestRemoveMappingRule = async (oemId, key) => {
    const url = removeMappingRuleUrl(oemId)({ key });
    const response = await fetchWithAuthHeader(url, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) throw new Error(`Failed to remove OEM mapping rule, oemId: ${oemId}`);

    return (await response.json()).value;
};

export const requestRunMappingRules = async (oemId, ruleIds, bookIds) => {
    const params = { mappingRuleIds: ruleIds, rpBookIds: bookIds, oemId: oemId };
    const url = 'api/RepairProcedure/odata/MappingRule/QueueMappingRules';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    if (!response.ok) throw new Error(`Failed to run rules, oemId: ${oemId}`);
};

export const queueUndoRule = async (oemId, ruleId, userId) => {
    const params = { oemId, ruleId, userId };
    const url = 'api/RepairProcedure/odata/MappingRule/QueueUndoRule';
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });
    if (!response.ok) throw new Error(`Failed to undo rule, oemId: ${oemId}`);
};

export const requestRuleableValuesAnalytics = async (oemId, filters) => {
    const url = `api/RepairProcedure/${oemId}/MappingRulesAnalytics/GetRuleCreatorValues`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
    });
    if (!response.ok) throw new Error(`Failed to get rulable parameters values, oemId: ${oemId}`);
    return await response.json();
};

export const requestColumnValuesAnalytics = async (oemId, filters, field) => {
    const url = `api/RepairProcedure/${oemId}/${field}/MappingRulesAnalytics/GetColumnValues`;
    const response = await fetchWithAuthHeader(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
    });
    if (!response.ok) throw new Error(`Failed to get column parameters values, oemId: ${oemId}, field: ${field}`);
    return await response.json();
};

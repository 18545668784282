import { useCallback, useRef } from 'react';
import { SORT_ORDER } from 'enums/SortOrderEnum';

const useSorting = () => {
    const sortRulesRef = useRef([]);

    const updateSortRules = useCallback((columnName, order) => {
        if (sortRulesRef.current.findIndex(e => e.columnName === columnName) === -1) {
            sortRulesRef.current = [...sortRulesRef.current, ...[{ columnName, order }]];
            return sortRulesRef.current;
        } else {
            sortRulesRef.current = sortRulesRef.current
                .map(x => (x.columnName === columnName ? { ...x, order: order } : x))
                .filter(x => x.order !== SORT_ORDER.none);
            return sortRulesRef.current;
        }
    }, []);

    return { updateSortRules };
};

export default useSorting;

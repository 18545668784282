import Confirmation from 'components/Shared/Confirmation/Confirmation';
import Table from 'components/Shared/Table/Table';
import React, { useEffect, useMemo, useState } from 'react';
import useImportManagerDashboard from './useImportManagerDashboard';
import EditScheduleModal from './EditScheduleModal/EditScheduleModal';
import ImportManagerDashboardContext, { ImportManagerDashboardContextType } from './importManagerDashboardContext';
import { requestActiveMessageCount } from 'api/RepairProcedureApi';

const topics = [
    'Downloader',
    'BookCrawler',
    'Importer',
    'Flagging.Text',
    'Flagging.Image',
    'ApplyMappingRules',
    'ApplyFlagDispositionRules',
];

const ImportManagerDashboard: React.FC = () => {
    const { headers, data, tableHandlers, confirmationRef, selectedSchedule, modalHandlers, importJobDownloadTypes } =
        useImportManagerDashboard();

    const [messageCounts, setMessageCounts] = useState<{ [key: string]: number }>({});

    useEffect(() => {
        const fetchAllMessageCounts = async () => {
            const counts = {};
            for (const topic of topics) {
                try {
                    const result = await requestActiveMessageCount(topic);
                    counts[topic] = result.activeMessageCount;
                } catch (error) {
                    console.error(`Error fetching active message count for ${topic}:`, error);
                }
            }

            setMessageCounts(counts);
        };

        fetchAllMessageCounts();
    }, []);

    const contextValue = useMemo<ImportManagerDashboardContextType>(
        () => ({ importJobDownloadTypes }),
        [importJobDownloadTypes]
    );

    return (
        <ImportManagerDashboardContext.Provider value={contextValue}>
            {Object.entries(messageCounts).length > 0 && (
                <div className="alert alert-info" role="alert">
                    <div className="d-flex flex-wrap justify-content-center">
                        <span className="fw-bold me-5">Active Message Counts</span>
                        <div className="ms-5">
                            {Object.entries(messageCounts).map(([topic, count], index) => (
                                <span key={index} className="me-4">
                                    {topic}: <span className="fw-bold">{count}</span>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <Table
                headers={headers}
                data={data}
                idKey="oemId"
                cellEventCallbacks={tableHandlers}
                tableHtmlId="import-manager-dashbaord"
            />
            <Confirmation ref={confirmationRef} />
            <EditScheduleModal
                data={selectedSchedule}
                onToggle={modalHandlers.onToggle}
                onPause={modalHandlers.onPause}
                onSave={modalHandlers.onSave}
            />
        </ImportManagerDashboardContext.Provider>
    );
};

export default ImportManagerDashboard;
